import React, {useCallback, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom"
import Slider from "react-slick"
import PrevArrow from "../common/PrevArrow";
import NextArrow from "../common/NextArrow"
import DefaultImage from "../../assets/image/noimage.jpg"

const AlbumDisplay = ({albumList, title, category, onPageChange}) => {
    const sliderRef = React.useRef(null)
    const navigate = useNavigate();
    useEffect(() => {
    }, [albumList]);
    const [slideIndex, setSlideIndex] = useState(0);
    const [moved, setMoved] = useState(false);

    const prevClick = useCallback(() => sliderRef.current.slickPrev(), []);
    const nextClick = useCallback(() => sliderRef.current.slickNext(), []);

    const settings = {
        // autoplay: true,
        // infinite: true,
        draggable: true,
        infinite: albumList.length > 5,
        slidesToShow: albumList.length > 5 ? 5 : albumList.length,
        slidesToScroll: albumList.length > 5 ? 5 : albumList.length,
        arrows: albumList.length > 5,
        dots: false,
        responsive: [
            {
                "breakpoint": 768,
                "settings": {
                    draggable: true,
                    infinite: albumList.length > 2,
                    slidesToShow: albumList.length > 2 ? 2 : albumList.length,
                    slidesToScroll: albumList.length > 2 ? 2 : albumList.length,
                    arrows: albumList.length > 2,
                    // dots: false,
                }
            },
            {
                "breakpoint": 992,
                "settings": {
                    draggable: true,
                    infinite: albumList.length > 3,
                    slidesToShow: albumList.length > 3 ? 3 : albumList.length,
                    slidesToScroll: albumList.length > 3 ? 3 : albumList.length,
                    arrows: albumList.length > 3,
                    // dots: false,
                }
            },
            {
                "breakpoint": 1199,
                "settings": {
                    // draggable: true,
                    infinite: albumList.length > 4,
                    slidesToShow: albumList.length > 4 ? 4 : albumList.length,
                    slidesToScroll: albumList.length > 4 ? 4 : albumList.length,
                    arrows: albumList.length > 4,
                    // dots: false,
                }
            },
            {
                "breakpoint": 1500,
                "settings": {
                    // draggable: true,
                    infinite: albumList.length > 5,
                    slidesToShow: albumList.length > 5 ? 5 : albumList.length,
                    slidesToScroll: albumList.length > 5 ? 5 : albumList.length,
                    arrows: albumList.length > 5,
                    // dots: false,
                }
            },
        ],
        prevArrow: <PrevArrow onClick={prevClick}/>,
        nextArrow: <NextArrow onClick={nextClick}/>
        // // 기타 옵션 설정
        //
        // customPaging: function (i) {
        //     return '<span></span>';
        // },
    };

    const AlbumDetail = (albumCode, albumName) => {
        navigate('/albumDetail/' + albumCode, {state: {from: window.location.pathname, name: albumName}});
    }


    // 슬라이드 마우스 클릭
    const upListenner = (albumCode, albumName) => {
        if (moved) {
            // console.log(moved)
        } else {
            AlbumDetail(albumCode, albumName)
        }
    }
    const moveListener = () => {
        setMoved(true);
    }
    const downListener = () => {
        setMoved(false);
    }

    const imageErrorHandler = (e) => {
        e.target.src = DefaultImage
    }

    return (
        <>
            <section className="space-bottom-3">
                <div className="container">
                    <header className="mb-5 d-md-flex justify-content-between align-items-center">
                        <h2 className="font-size-7 mb-3 mb-md-0">{title}</h2>
                        <Link
                            to={category === 0 ? '/best' : category === 1 ? '/new' : category === 2 ? '/song' : category === 3 ? '/popjazz' : '/classic'}
                            className="h-primary d-block">더보기 <i
                            className="glyph-icon flaticon-next"></i></Link>
                    </header>

                    <Slider ref={sliderRef} {...settings}
                            className="js-slick-carousel products no-gutters border-top border-left border-right">
                        {/* 아래 div 반복, 5개 이상되면 자동으로 < > 이동 버튼 생성됨*/}
                        {albumList.map((album, index) => {
                            return <div className="product" key={album.albumCode}
                                        onMouseUp={() => upListenner(album.albumCode, album.albumName)}
                                        onMouseMove={moveListener}
                                        onMouseDown={downListener}

                            >
                                <div className="product__inner overflow-hidden p-3 p-md-4d875"
                                     style={{cursor: "pointer"}}>
                                    <div
                                        className="woocommerce-LoopProduct-link woocommerce-loop-product__link d-block position-relative">
                                        <div className="woocommerce-loop-product__thumbnail">
                                            <a className="d-block"><img
                                                src = {album.thumbnailUrl ? album.thumbnailUrl : DefaultImage}
                                                 width={"125px"} height={"125px"}
                                                className="d-block mx-auto attachment-shop_catalog size-shop_catalog wp-post-image"
                                                alt="image-description" onError={imageErrorHandler}/></a>
                                        </div>
                                        <div className="woocommerce-loop-product__body product__body pt-3 bg-white">
                                            <div className="text-uppercase font-size-1 mb-1 text-truncate"><a
                                            >{album.categoryName}</a></div>
                                            <h2 className="woocommerce-loop-product__title product__title h6 text-lh-md mb-1 text-height-2 crop-text-2 h-dark">
                                                <a>{album.albumName}</a></h2>
                                            <div className="font-size-2  mb-1 text-truncate"><a
                                                className="text-gray-700">{album.albumArtist}</a>
                                            </div>
                                            <div
                                                className="price d-flex align-items-center font-weight-medium font-size-3">
                                            <span className="woocommerce-Price-amount amount"><span
                                                className="woocommerce-Price-currencySymbol"></span>{album.albumPrice.toLocaleString()}원</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}

                    </Slider>
                </div>
            </section>
        </>
    )
}

export default AlbumDisplay;