import React from 'react';
import {useLocation} from 'react-router-dom';
import DOMPurify from 'dompurify'

const BoardDetail = () => {
    const location = useLocation();
    const data = location.state.data;
    const sanitizedDescription = (description) => {
        return DOMPurify.sanitize(description);
    }
    return(
        <>
            <div className="container max-width-940">
                <div className="space-bottom-1 space-bottom-lg-2 space-bottom-xl-3">
                    <div className="pb-lg-4">

                        <div className="py-4 py-lg-5 py-xl-8" >
                            <h6 className="font-weight-medium font-size-7 font-size-xs-25 text-center"  style={{wordBreak: "break-word", whiteSpace: "pre-wrap"}}>{data.title}</h6>
                        </div>
                        <div className="font-size-2 mb-0 text-lh-1dot72" style={{wordBreak: "break-word"}}
                           dangerouslySetInnerHTML={{__html: sanitizedDescription(data.content)}}></div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default BoardDetail;