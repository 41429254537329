import React, {useEffect, useState} from 'react';
import {BannerTop, BannerAlbum, AlbumDisplay} from "../components/home";
import axios from "axios";
import LoadingBar from "../components/common/LoadingBar"

const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT;


const Home = ({onPageChange}) => {
    const [staticBanner, setStaticBanner] = useState(null);
    const [rollingBannerList, setRollingBannerList] = useState([]);
    const [bestAlbumList, setBestAlbumList] = useState([]);
    const [newAlbumList, setNewAlbumList] = useState([]);
    const [songAlbumList, setSongAlbumList] = useState([]);
    const [popJazzAlbumList, setPopJazzAlbumList] = useState([]);
    const [classicAlbumList, setClassicAlbumList] = useState([]);
    const [recommendAlbumList, setRecommendAlbumList] = useState([]);
    const [loading, setLoading] = useState(true)

    const fetchAllProducts = () => {
        axios.get(apiUrl + "/product/public/main/list?sortType=1")
            .then((res) => {
                // setBannerList( res.data)
                const categories = res.data.albums;
                categories.forEach(e => {
                    switch (e.category) {
                        case 0:
                            setBestAlbumList(e.albumList);
                            break;
                        case 1:
                            setNewAlbumList(e.albumList);
                            break;
                        case 2:
                            setSongAlbumList(e.albumList);
                            break;
                        case 3:
                            setPopJazzAlbumList(e.albumList);
                            break;
                        case 4:
                            setClassicAlbumList(e.albumList);
                            break;
                        default:

                    }
                })
                const bannerList = res.data.topBanners
                if (bannerList && bannerList.length > 0) {
                    let staticBannerIdx = 0;
                    const rollingBannerList = bannerList.map((e, index) => {
                        if (e.bannerType !== 0) {
                            return e
                        } else {
                            staticBannerIdx = index
                        }
                    })
                    const staticBanner = bannerList[staticBannerIdx]
                    setStaticBanner(staticBanner);
                    setRollingBannerList(rollingBannerList);
                }
                setRecommendAlbumList(res.data.recommendAlbums)
            })
            .catch(err => {
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        // fetchTopBannerList();
        fetchAllProducts();
    }, []);

    useEffect(() => {
        const shouldOpenSidebar = sessionStorage.getItem("openSidebarAfterRedirect");
        if (shouldOpenSidebar) {
            alert("로그인이 필요합니다.")
            openSidebar();
            sessionStorage.removeItem("openSidebarAfterRedirect"); // 플래그 제거
        }
    }, []);
    function openSidebar() {
        const sidebarToggleBtn = document.getElementById('sidebarNavToggler');
        if (sidebarToggleBtn) {
            sidebarToggleBtn.click(); // 버튼 클릭 이벤트
        }
    }


    if (loading) {
        return (
            <div style={{height: '100vh', display: 'block'}}>
                <LoadingBar/>
            </div>
        )
    } else {
        return (
            <>
                {/* 상단 배너 */}
                <BannerTop
                    staticBanner={staticBanner} rollingBannerList={rollingBannerList}
                />
                {/* 베스트 */}
                <AlbumDisplay
                    albumList={bestAlbumList} title={"베스트"} category={0} onPageChange={onPageChange}
                />
                {/* New */}
                <AlbumDisplay
                    albumList={newAlbumList} title={"New"} category={1} onPageChange={onPageChange}
                />
                {/* 추천 앨범 */}
                <BannerAlbum
                    albumList={recommendAlbumList}
                />
                {/* 가요 */}
                <AlbumDisplay
                    albumList={songAlbumList} title={"가요"} category={2} onPageChange={onPageChange}
                />
                {/* 팝/재즈 */}
                <AlbumDisplay
                    albumList={popJazzAlbumList} title={"팝/재즈"} category={3} onPageChange={onPageChange}
                />
                {/* 클래식 */}
                <AlbumDisplay
                    albumList={classicAlbumList} title={"클래식"} category={4} onPageChange={onPageChange}
                />
            </>
        )
    }
}

export default Home;
