import React from 'react';
import {Link} from 'react-router-dom';
import logo from "../../images/pandori_logo_v3.png";

const Footer = () => {
    return (
        <footer>
            <div className="">
                <div className="border-top border-bottom pb-5 space-bottom-lg-1 space-top-lg-2">
                    <div className="container">
                        <div className="p-4">
                            <ul className="nav">
                                <li className="nav-item pr-2  border-right">
                                    <Link className="h-primary font-size-2" to='/usageTerms'>이용약관</Link>
                                </li>
                                <li className="nav-item pr-2 pl-2 border-right">
                                    <Link className="h-primary font-size-2" to='/privacyPolicy'>개인정보처리방침</Link>
                                </li>
                                <li className="nav-item pr-2 pl-2">
                                    <Link className="h-primary font-size-2" to='/inquiry'>1:1 문의</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="d-md-flex align-items-center mb-5">
                            <div className="p-4">
                                <img src={logo} alt="logo image" width="120px"/>
                            </div>
                            <div className="mx-md-8">
                                <span className="font-size-2 text-gray-500">
                                    (주)엠알씨 | 경기도 김포시 김포한강5로 321, 김포한강튜클래스 1001호 | 사업자 등록 번호 128-86-59443 | 대표 곽근주 | 
                                    통신판매신고번호 2024-경기김포-8132 | 유선번호 031-5186-0750
                                </span>
                                <p className="font-size-2 text-gray-500 mb-0 mt-1">COPYRIGHTⓒMRC ALL RIGHTS RESERVED.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default Footer;