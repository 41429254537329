import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import axiosInstanceWithToken from "../../containers/axiosInstanceWithToken"
import MakeTimeStamp from "../common/MakeTimeStamp";
import SHA256 from 'sha256';
// import ModalComponent from "../common/CommonModal"
import {basketUpdate} from "../../store/action/BasketAction";
import '../myPage/themeWrapper.css'
import LoadingBar from "../common/LoadingBar";
import './mobile.css'

const frontendUrl = process.env.REACT_APP_FRONTEND
const mid = process.env.REACT_APP_INICIS_MID

const testURL = "https://stgstdpay.inicis.com/stdjs/INIStdPay.js";
const releaseURL = "https://stdpay.inicis.com/stdjs/INIStdPay.js";

// PC 결제수단 반환
const payServerText = (index) => {
    if (index === 0) {
        return "Card"; // 카드
    } else if (index === 1) {
        return "VBank"; // 무통장
    } else if (index === 2) {
        return "HPP"; // 핸드폰
    } else if (index === 3) {
        return "DirectBank"; // 계좌이체
    }
}

// Mobile 결제수단 반환
const payServerTextMb = (index) => {
    if (index === 0) {
        return "CARD"; // 카드
    } else if (index === 1) {
        return "VBANK"; // 무통장
    } else if (index === 2) {
        return "MOBILE"; // 핸드폰
    } else if (index === 3) {
        return "BANK"; // 계좌이체
    }
}

const BasketItems = ({pageType}) => {
    const [basketList, setBasketList] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const [payData, setPayData] = useState({});
    const [timestamp, setTimestamp] = useState(0);
    const [loading, setLoading] = useState(true)
    // const [modalFlag, setModalFlag] = useState(false)
    // const [modalContent, setModalContent] = useState({})
    const basketUpdateDispatch = useDispatch();

    // axios.get(apiEndpoint + '/basket/info', {
    // headers: {
    //     Authorization: localStorage.getItem('authorization')
    // }
    // }).then(res => {console.log(res)})
    const loadBasketList = () => {
        axiosInstanceWithToken.get('/basket/info')
            .then(res => {
                const albumList = res
                let totalPrice = 0
                albumList.forEach(album => {
                    const addTrackCount = album.productList.reduce((acc, product) => {
                        if (product.productType === 2) {
                            acc = acc + 1
                        }
                        return acc
                    }, 0)
                    if (addTrackCount === album.trackCount) {
                        album.totalPrice = album.albumPrice
                    } else {
                        album.totalPrice = album.productList.reduce((acc, product) => {
                            acc = acc + product.productPrice
                            return acc
                        }, 0)
                        album.totalPrice += album.productList.filter(product => product.productType === 3).length > 0 ? album.artworkPrice : 0
                    }
                    totalPrice += album.totalPrice
                })
                setBasketList(albumList)
                setTotalPrice(totalPrice)
            })
            .catch(err => {
                console.log(err)
                setBasketList([])
            })
            .finally(() => {
                setLoading(false)
            })
    }
    useEffect(() => {
        loadBasketList()
    }, []);

    const orderBasketItems = () => {
        if (basketList.length != 0) {
            const productIdList = basketList.reduce((acc, album) => {
                return acc.concat(album.productList.map((product) => product.productId))
            }, [])
            const productNameList = basketList.reduce((acc, album) => {
                return acc.concat(album.productList.map((product) => product.productName))
            }, [])
            console.log(productIdList)
            axiosInstanceWithToken.post("/order/regular", {productIdList: productIdList, price: totalPrice})
                .then(res => {
                    if (res) {
                        registerPaymentInfo(res, productNameList)
                    } else {
                        alert("주문 정보 입력에 실패했습니다.")
                    }
                })
                .catch(error => {
                    alert("주문 정보 입력에 실패했습니다.")
                    console.log(error)
                })
        } else {
            alert("장바구니가 비어있습니다.")
        }
    }
    const registerPaymentInfo = (orderInfo, productNameList) => {
        axiosInstanceWithToken.post("/payment/regular", {
            orderSeq: orderInfo.orderSeq,
            price: totalPrice,
            userSeq: orderInfo.userSeq
        })
            .then(res => {
                if (res) {
                    const payData = {
                        paymentId: res,
                        productName: productNameList.length > 1 ? productNameList[0] + " 외 " + (productNameList.length - 1) + "제품" : productNameList[0],
                        buyerName: '송한수',
                        buyerTel: '01062780864',
                        buyerEmail: orderInfo.userEmail,
                        productPrice: totalPrice,
                        payStatus: 0,
                        returnUrl: frontendUrl + '/node/api/payment',
                        // closeUrl: frontendUrl + '/node/api/payment/close'
                        closeUrl: frontendUrl + '/basket/close-inicis'
                    }
                    setPayData(payData)
                    // store.dispatch(inicisOverlayOpen());
                    // handleInicisOverlayFlag(true)
                    onClickPurchase()
                } else {
                    alert("결제 정보 입력에 실패했습니다.")
                }
            })
            .catch(error => {
                console.log(error)
                alert("결제 정보 입력에 실패했습니다.")
            })
    }

    const onClickPurchase = () => {
        const _timeStamp = MakeTimeStamp();
        setTimestamp(_timeStamp);
        const script = document.createElement("script");
        script.src = testURL;
        document.head.appendChild(script);
        script.onload = e => {
            e.srcElement.ownerDocument.defaultView.INIStdPay.pay('SendPayForm_id');
        }
        // eslint-disable-next-line no-unused-expressions
    }

    const confirmRemoveBasketItem = (param) => {
        let contentName = ''
        if (Array.isArray(param)) {
            contentName = param[0].productName + (param.length === 1 ? '' : ('외 ' + (param.length - 1) + '개의 아트워크'))
        } else if (param.productId) {
            contentName = param.productName
        } else {
            contentName = param.albumName
        }
        if (window.confirm(`'` + contentName + `'` + '을(를) 장바구니에서 삭제 하시겠습니까?')) {
            removeBasketItem(param)
        }
        // setModalContent({
        //     title: '장바구니 삭제',
        //     body: `'` + contentName + `'` + '을(를) 장바구니에서 삭제 하시겠습니까?',
        //     data: param
        // })
        // setModalFlag(true)
    }

    const removeBasketItem = (param) => {
        let productIdList = []
        if (Array.isArray(param)) {
            productIdList = param.map(product => product.productId)
        } else if (param.productId) {
            productIdList.push(param.productId)
        } else {
            productIdList = param.productList.map(product => product.productId)
        }
        axiosInstanceWithToken.delete("/basket/delete", {
            data: {
                productIdList: productIdList
            }
        })
            .then(res => {
                if (res) {
                    basketUpdateDispatch(basketUpdate())
                    loadBasketList()
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    if (!loading && basketList) {
        return (
            <div className={pageType === 'basket' ? "tab-pane fade show active" : "tab-pane fade"}
                 id="pills-two-example1" role="tabpanel"
                 aria-labelledby="pills-two-example1-tab">
                <div className="pt-5 pl-md-5 pt-lg-8 pl-lg-9 space-bottom-lg-2 mb-lg-4">
                    <h6 className="font-weight-medium font-size-7 ml-lg-1 mb-lg-8 pb-xl-1">장바구니</h6>

                    {basketList.length > 0 && <table className="table mb-0 ">
                        <thead>
                        <tr className="border ">
                            <th scope="col" className="py-3 border-bottom-0 font-weight-medium pl-3 pl-md-6 pr-9">상품
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        {basketList.map((album) => {
                            return <tr key={album.albumCode} className="border">
                                <th className="pl-3 pl-md-5 font-weight-normal align-middle py-6" colSpan="3">
                                    <div className="d-flex mobile-row-th">
                                        <Link style={{width: '15%', minWidth: '125px', verticalAlign: 'top'}}>
                                            <img className="img-fluid"
                                                 src={album.thumbnailUrl}
                                                 alt="Image-Description"/>
                                        </Link>
                                        <div className="ml-xl-4 mr-xl-4" style={{width: '85%'}}>
                                            {/*<div className="font-weight-normal">*/}
                                            {/*    <a href="#">The Overdue Life of Amy Byler</a>*/}
                                            {/*</div>*/}
                                            {/*<div className="font-size-2"><a href="#" className="text-gray-700" tabIndex="0">Jay*/}
                                            {/*    Shetty</a></div>*/}
                                            <table className="table mb-0">
                                                <thead>
                                                <tr className="">
                                                    <th scope="col"
                                                        className="py-3 border-bottom-0 font-weight-medium pl-3 pl-md-5 bg-punch-light"
                                                        style={{width: '70%'}}>{album.albumName}

                                                    </th>
                                                    <th scope="col"
                                                        className="py-3 border-bottom-0 font-weight-medium pl-3 pl-md-5 bg-punch-light"
                                                        style={{width: '20%'}}>
                                                    </th>
                                                    <th scope="col"
                                                        className="py-3 border-bottom-0 font-weight-medium pl-3 pl-md-5 bg-punch-light"
                                                        style={{width: '10%'}}>
                                                        <Link className="remove" aria-label="Remove this item"
                                                              onClick={() => confirmRemoveBasketItem(album)}>
                                                            <svg xlinkHref="http://www.w3.org/2000/svg"
                                                                 xlinkHref="http://www.w3.org/1999/xlink" width="15px"
                                                                 height="15px">
                                                                <path fillRule="evenodd" fill="rgb(22, 22, 25)"
                                                                      d="M15.011,13.899 L13.899,15.012 L7.500,8.613 L1.101,15.012 L-0.012,13.899 L6.387,7.500 L-0.012,1.101 L1.101,-0.012 L7.500,6.387 L13.899,-0.012 L15.011,1.101 L8.613,7.500 L15.011,13.899 Z"/>
                                                            </svg>
                                                        </Link>
                                                    </th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                {album.productList.map((product) => {
                                                    if (product.productType === 2) {
                                                        return <tr key={product.productId} className="border-bottom">
                                                            <th className="pl-3  font-weight-normal align-middle border-bottom">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="ml-xl-4 ">
                                                                        <div className="font-size-2">
                                                                        <span
                                                                            className="text-gray-700">{product.productName}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <td className="align-middle py-5 text-center">{product.productPrice.toLocaleString()}원</td>
                                                            <td className="align-middle py-5">
                                                                <Link className="remove" aria-label="Remove this item"
                                                                      onClick={() => confirmRemoveBasketItem(product)}>
                                                                    <svg xlinkHref="http://www.w3.org/2000/svg"
                                                                         xlinkHref="http://www.w3.org/1999/xlink"
                                                                         width="15px"
                                                                         height="15px">
                                                                        <path fillRule="evenodd" fill="rgb(22, 22, 25)"
                                                                              d="M15.011,13.899 L13.899,15.012 L7.500,8.613 L1.101,15.012 L-0.012,13.899 L6.387,7.500 L-0.012,1.101 L1.101,-0.012 L7.500,6.387 L13.899,-0.012 L15.011,1.101 L8.613,7.500 L15.011,13.899 Z"/>
                                                                    </svg>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    } else if (product.productType === 3 && product.productIndex === 0) {
                                                        return <tr key={product.productId} className="border-bottom-0">
                                                            <th className="pl-3  font-weight-normal align-middle">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="ml-xl-4 ">
                                                                        <div className="font-size-2">
                                                                        <span
                                                                            className="text-gray-700">{product.productName}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <td className="align-middle py-5 text-center"
                                                                rowSpan={album.productList.filter(product => product.productType === 3).length}>
                                                                <div>{
                                                                    album.trackCount === album.productList.filter(product => product.productType === 2).length ?
                                                                        <del>{album.artworkPrice.toLocaleString()}</del>
                                                                        : album.artworkPrice.toLocaleString()
                                                                }원
                                                                </div>
                                                                {
                                                                    album.trackCount === album.productList.filter(product => product.productType === 2).length ?
                                                                        <strong>0원</strong> : ''
                                                                }
                                                            </td>
                                                            <td className="align-middle py-5"
                                                                rowSpan={album.productList.filter(product => product.productType === 3).length}>
                                                                <Link className="remove" aria-label="Remove this item"
                                                                      onClick={() => confirmRemoveBasketItem(album.productList.filter(product => product.productType === 3))}>
                                                                    <svg xlinkHref="http://www.w3.org/2000/svg"
                                                                         xlinkHref="http://www.w3.org/1999/xlink"
                                                                         width="15px"
                                                                         height="15px">
                                                                        <path fillRule="evenodd" fill="rgb(22, 22, 25)"
                                                                              d="M15.011,13.899 L13.899,15.012 L7.500,8.613 L1.101,15.012 L-0.012,13.899 L6.387,7.500 L-0.012,1.101 L1.101,-0.012 L7.500,6.387 L13.899,-0.012 L15.011,1.101 L8.613,7.500 L15.011,13.899 Z"/>
                                                                    </svg>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    } else if (product.productType === 3) {
                                                        return <tr key={product.productId} className="border-bottom-0">
                                                            <th className="pl-3  font-weight-normal align-middle border-top-0">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="ml-xl-4 ">
                                                                        <div className="font-size-2">
                                                                        <span
                                                                            className="text-gray-700">{product.productName}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    }
                                                })}

                                                <tr>
                                                    <td className="text-right mr-3" colSpan="3">앨범 총 금액
                                                        : <span className="text-gray-500 mr-2"
                                                                style={{textDecoration: "line-through"}}></span><span>{album.totalPrice.toLocaleString()}원</span>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </th>
                                {/*<td className="align-middle py-5">$37</td>*/}
                                {/*<td className="align-middle py-5">*/}
                                {/*    <span className="product__add-to-cart">ADD TO CART</span>*/}
                                {/*</td>*/}
                            </tr>
                        })}
                        <tr>
                            <td colSpan="2">
                                <div className="text-xl-right font-size-3 pt-3">
                                    전체 상품 금액 : {totalPrice.toLocaleString()}원
                                </div>
                            </td>
                        </tr>
                        <tr className="text-right">
                            <td colSpan="2" className="border-top-0">
                                <button
                                    className="btn btn-wide btn-dark text-white rounded-0 transition-3d-hover height-60 width-390"
                                    onClick={orderBasketItems}>구매하기
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>}
                    {basketList.length === 0 &&
                        <div className="space-2 space-md-3 space-lg-4 space-top-xl-2 space-bottom-xl-3">
                            <div className="d-flex flex-column align-items-center">
                                <div className="font-weight-medium font-size-15 font-size-xs-25 mb-3">장바구니가 비었습니다.
                                </div>
                                <h6 className="font-weight-medium mb-2 col-lg-7 text-center text-lh-lg mb-5">상품(앨범, 곡,
                                    아트웍)을 장바구니에 담아주세요.</h6>
                            </div>
                        </div>}
                </div>
                <div style={{display: "none"}}>

                    {/* 이니시스 PC 결제 폼 */}
                    <form id="SendPayForm_id" name="" method="Post">
                        <input type="text" readOnly name="goodname" value={payData.productName}/>
                        <input type="text" readOnly name="buyername" value={payData.buyerName}/>
                        <input type="text" readOnly name="buyertel" value={payData.buyerTel}/>
                        <input type="text" readOnly name="buyeremail" value={payData.buyerEmail}/>
                        <input type="text" readOnly name="price" value={payData.productPrice}/>
                        <input type="hidden" readOnly name="mid" value={process.env.REACT_APP_INICIS_MID}/>
                        <input type="hidden" readOnly name="gopaymethod" value={payServerText(payData.payStatus)}/>
                        <input type="hidden" readOnly name="mKey" value={process.env.REACT_APP_INICIS_MKEY}/>
                        <input type="hidden" readOnly name="signature"
                               value={SHA256(`oid=${payData.paymentId}&price=${payData.productPrice}&timestamp=${timestamp}`)}/>
                        <input type="hidden" readOnly name="oid" value={payData.paymentId}/>
                        <input type="hidden" readOnly name="timestamp" value={timestamp}/>
                        <input type="hidden" readOnly name="version" value="1.0"/>
                        <input type="hidden" readOnly name="currency" value="WON"/>
                        <input type="hidden" readOnly name="payViewType" value="overlay"/>

                        <input
                            type="hidden"
                            readOnly
                            name="returnUrl"
                            value={payData.returnUrl}
                        />

                        <input
                            type="hidden"
                            readOnly
                            name="closeUrl"
                            value={payData.closeUrl}
                        />
                    </form>
                </div>
                {/*<ModalComponent show={modalFlag}*/}
                {/*                onConfirm={removeBasketItem}*/}
                {/*                onHide={() => setModalFlag(false)}*/}
                {/*                content={modalContent}*/}
                {/*                confirmButtonText={'삭제'}*/}
                {/*                cancelButtonText={'취소'}*/}
                {/*></ModalComponent>*/}
            </div>
        )
    } else {
        return (
            <div style={{height: '100vh', display: 'block'}}>
                <LoadingBar/>
            </div>
        )
    }

}

export default BasketItems