import React from "react";

const PrevArrow = ({onClick}) => {
    return (
        <div
            className={"js-prev d-none d-lg-block u-slick__arrow u-slick__arrow-centered--y rounded-circle fas fa-chevron-left u-slick__arrow-inner u-slick__arrow-inner--left ml-lg-n3d50 ml-wd-n4"}
            onClick={onClick}
        />
    );
}
export default PrevArrow;