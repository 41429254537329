import React, {useEffect, useState, useRef} from "react";
import {useLocation} from "react-router-dom";
import $ from "jquery"
import "bootstrap-select/dist/js/bootstrap-select"
import "bootstrap-select/dist/css/bootstrap-select.css"
import "bootstrap-select/dist/css/bootstrap-select.min.css"
import "bootstrap-select/dist/css/bootstrap-select.css.map"

const SelectBox = ({handleSelect, selectList, selected, isSearchBox, searchBoxHandler, doSearch}) => {
    const location = useLocation();
    const [text,setText] = useState('');
    const inputRef = useRef(null)
    // const selectRef = useRef()
    useEffect(() => {
        $(".selectpicker").selectpicker('refresh')
        $(".dropdown-menu.inner").addClass("show")
    }, []);

    const textClear = () => {
        inputRef.current.value = null;
    }
    const searchBoxKeyDown = (e) => {
        setText(e.target.value)
        if(e.key === 'Enter') {
            doSearch(e)
        }
    }

    if(isSearchBox) {
        return <div className="form-inline woocommerce-ordering mb-4 m-md-0">
            <select
                onChange={handleSelect}
                className="js-select selectpicker dropdown-select orderby max-width-130"
                name="orderby"
                data-style="border-bottom shadow-none outline-none py-2"
            >
                {selectList.map((e) => {
                    return <option key={e.value} value={e.value}>{e.label}</option>;
                })}
            </select>
            <div className="d-flex">
                <div className="input-group-prepend">
                    <i className="glph-icon flaticon-loupe input-group-text py-2d75 bg-white-100 border-white-100"></i>
                </div>
                <input ref={inputRef}
                    className="form-control bg-white-100 py-2d75 height-4 border-white-100"
                    type="search" placeholder={"Search"}
                    aria-label="Search" onChange={searchBoxHandler} onKeyDown={searchBoxKeyDown} />
            </div>
        </div>
    } else {
        return <div className="form-check-inline woocommerce-ordering mb-4 m-md-0">
            <select
                onChange={handleSelect}
                className="js-select selectpicker dropdown-select orderby max-width-180"
                name="orderby"
                data-style="border-bottom shadow-none outline-none py-2"
            >
                {selectList.map((e) => {
                    return <option key={e.value} value={e.value}>{e.label}</option>;
                })}
            </select>
        </div>
    }
}
export default SelectBox;