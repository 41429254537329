import React, {useEffect, useState} from "react";
import * as userService from "../../containers/userService";
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import axios from "axios";
import DOMPurify from 'dompurify'

const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT;
const JoinInputMobile = ({setIsLoading}) => {
    const [privacy , setPrivacy] = useState();
    const [terms, setTerms] = useState();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => {
        userService.Join(data, setIsLoading);
    }
    const userPwd = watch('userPwd');
    const sanitizedDescription = (description) => {
        return DOMPurify.sanitize(description);
    }

    useEffect(() => {
        axios.get(apiUrl + "/common/public/board/join-terms")
            .then(res => {
                const resultData = res.data;
                if(resultData[0].type === 1){
                    setTerms(resultData[0].content)
                    setPrivacy(resultData[1].content)
                }

            })
            .catch(err => {

            })
    }, []);

    return (
        <form className="" onSubmit={handleSubmit(onSubmit)}>
            <div id="signup1" style={{display: 'none', opacity: '0'}}
                 data-target-group="idForm1">
                <header className="border-bottom px-4 px-md-6 py-4">
                    <h2 className="font-size-3 mb-0 d-flex align-items-center"><i
                        className="flaticon-resume mr-3 font-size-5"></i>회원가입</h2>
                </header>

                <div className="p-4 p-md-6">
                    <div className="form-group mb-4">
                        <div className="js-form-message js-focus-state">
                            <label id="signinEmailLabel11" className="form-label"
                                   htmlFor="signinEmail11">이메일</label>
                            <input type="email"
                                   className="form-control rounded-0 height-4 px-4"
                                   name="email" id="signinEmail11"
                                   placeholder="user@pandori.com"
                                   aria-label="user@pandori.com"
                                   aria-describedby="signinEmailLabel11"
                                   {...register("userEmail", {required: "이메일을 입력해주세요."})}/>
                            {errors.userEmail &&
                                <p className="font-size-2 text-primary">{errors.userEmail.message}</p>}
                        </div>
                    </div>

                    <div className="form-group mb-4">
                        <div className="js-form-message js-focus-state">
                            <label id="signinPasswordLabel11" className="form-label"
                                   htmlFor="signinPassword11">비밀번호</label>
                            <input type="password"
                                   className="form-control rounded-0 height-4 px-4"
                                   name="password" id="signinPassword11" placeholder="8~16자의 영문 대/소문자, 숫자, 특수문자"
                                   aria-label="" aria-describedby="signinPasswordLabel11"
                                   {...register("userPwd", {
                                       required: "비밀번호를 입력해주세요.",
                                       pattern: {
                                           value: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,16}$/,
                                           message: "8~16자의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요."
                                       }
                                   })}/>
                            {errors.userPwd &&
                                <p className="font-size-2 text-primary">{errors.userPwd.message}</p>}
                        </div>
                    </div>

                    <div className="form-group mb-4">
                        <div className="js-form-message js-focus-state">
                            <label id="signupConfirmPasswordLabel9" className="form-label"
                                   htmlFor="signupConfirmPassword9">비밀번호 재입력</label>
                            <input type="password"
                                   className="form-control rounded-0 height-4 px-4"
                                   name="confirmPassword" id="signupConfirmPassword9"
                                   placeholder="" aria-label=""
                                   aria-describedby="signupConfirmPasswordLabel9"
                                   {...register("confirmPwd", {
                                       validate: (value) => value === userPwd || '비밀번호가 일치하지 않습니다'
                                   })} />
                            {errors.confirmPwd &&
                                <p className="font-size-2 text-primary">{errors.confirmPwd.message}</p>}
                        </div>
                    </div>

                    <div className="form-group mb-4">
                        <div className="js-form-message js-focus-state">
                            <input id="signTerms" type="checkbox" className="mr-2"
                                   {...register("signTerms", {required: "이용약관에 동의해주세요."})}
                            />
                            <label id="signTermsLabel" className=" form-label"
                                   htmlFor="signTerms"><span className="text-red-1 font-weight-bold mr-2">[필수]</span>판도리
                                이용약관</label>
                            <div className="mt-4 pb-3 font-size-2" id="terms" style={{
                                    height: '100px',
                                    overflowY: 'auto',
                                    border: '1px solid #dfdcd7',
                                    whiteSpace: "pre-wrap"
                                }}
                                 dangerouslySetInnerHTML={{__html: sanitizedDescription(terms)}}>
                            </div>
                            {errors.signTerms && <p className="font-size-2 text-primary">{errors.signTerms.message}</p>}
                        </div>
                    </div>

                    <div className="form-group mb-4">
                        <div className="js-form-message js-focus-state">
                            <input id="privacyPolicy" type="checkbox" className="mr-2"
                                   {...register("privacyPolicy", {required: "개인정보 수집 및 이용에 동의해주세요."})}
                            />
                            <label id="privacyPolicyLabel" className="form-label"
                                   htmlFor="privacyPolicy"><span
                                className="text-red-1 font-weight-bold mr-2">[필수]</span>개인정보 수집 및 이용</label>
                            <div className="mt-4 pb-3 font-size-2" id="privacy" style={{
                                    height: '100px',
                                    overflowY: 'auto',
                                    border: '1px solid #dfdcd7',
                                    whiteSpace: "pre-wrap"
                                }}
                                 dangerouslySetInnerHTML={{__html: sanitizedDescription(privacy)}}>
                            </div>
                            {errors.privacyPolicy &&
                                <p className="font-size-2 text-primary">{errors.privacyPolicy.message}</p>}
                        </div>
                    </div>

                    <div className="mb-3">
                        <button type="submit"
                                className="btn btn-block py-3 rounded-0 btn-dark">회원가입
                        </button>
                    </div>

                    <div className="text-center mb-4">
                        <span className="small text-muted">이미 계정이 있으신가요? </span>
                        <Link className="js-animation-link small"
                              data-target="#login1"
                              data-link-group="idForm1"
                              data-animation-in="fadeIn">로그인
                        </Link>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default JoinInputMobile