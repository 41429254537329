import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import ModalStyle from "./DownloadModal.module.css"
import {useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';

const DownloadProgressModalComponent = (props) => {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" className={ModalStyle} centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    다운로드중...
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="grid-example">
                <Container>
                    <Row>
                        <Col xs={12} md={18}>
                            <div className="progress">
                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={{width: props.body.percent + '%'}} aria-valuemin="0" aria-valuemax="100" style={{width: props.body.percent + '%'}}></div>
                            </div>
                        </Col>
                        {/*<Col xs={6} md={4}>*/}
                        {/*    .col-xs-6 .col-md-4*/}
                        {/*</Col>*/}
                    </Row>
                </Container>
            </Modal.Body>
            <div style={{borderTop: "1px solid #eae8e4"}}>
                <div className="m-3">
                    파일명: {props.footer.content}
                </div>
                <div className="m-3 d-flex" style={{justifyContent: "flex-start"}}>
                    <Button id={'downloadCancelBtn'} variant="primary" onClick={() => {props.onHide(props.footer.cancel.cancelTokenSource)}}>{props.footer.cancel.text}</Button>
                </div>
            </div>
        </Modal>
    );
}

export default DownloadProgressModalComponent;