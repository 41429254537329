import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {BoardThunk} from "../../store/reducer/BoardThunk";
import DOMPurify from 'dompurify'

const Guide = ({pageType, dataType}) => {

    const dispatch = useDispatch();
    const boardState = useSelector((state) => state.board)
    const { data, error } = boardState;
    const sanitizedDescription = (description) => {
        return DOMPurify.sanitize(description);
    }

    useEffect(() => {
        dispatch(BoardThunk(dataType));
    }, [dispatch, dataType]);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }


    return(
        <>
            <div className={pageType === 'guide' ? 'tab-pane fade show active' : "tab-pane fade"} id="guide"
                 role="tabpanel" aria-labelledby="guide-tab">

                <div className="mb-5 mb-lg-6 pb-xl-1">
                    <div className="container">
                        {(data.length === 0) && (<div className="text-center">작성된 게시글이 없습니다.</div>)}
                        {data &&
                            data.map((datalist, index) => (<div key={index}>
                                <div className="max-width-940 mx-auto bg-white py-xl-9" key={index}>
                                    <div className=" mt-md-n13 pt-lg-7 pl-xl-8 pr-xl-8">
                                        <div className="py-4 py-lg-5 py-xl-8" style={{wordBreak: "break-word"}}>
                                            <h6 className="font-weight-medium font-size-7 font-size-xs-25 text-center">{datalist.title}</h6>
                                        </div>
                                        <div className="mb-5 mb-lg-7">
                                            <div className="font-size-2 text-secondary-gray-700 text-right">
                                                <span>{formatDate(datalist.createdAt)}</span>
                                            </div>
                                        </div>
                                        <div style={{wordBreak: "break-word"}}
                                             dangerouslySetInnerHTML={{__html: sanitizedDescription(datalist.content)}}
                                        ></div>
                                        {datalist.boardFileUrl && (
                                            <div className="text-center pt-5">
                                                <img className="img-fluid" src={datalist.boardFileUrl}
                                                     alt="Image-Description"/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>))}

                    </div>
                </div>
            </div>
        </>
    )
}

export default Guide;