import {useState, CSSProperties} from "react";
import {BounceLoader} from 'react-spinners'
import "../../css/spinners.css"

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
};

const LoadingBar = () => {
    return <div className={"loader-wrapper"}>
        <BounceLoader
            cssOverride={override}
        />
    </div>
}
export default LoadingBar;