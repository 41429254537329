import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import { InquiryThunk} from "../../store/reducer/BoardThunk";
import Pagination from "../Pagination";
import { Link } from 'react-router-dom';
import "./boardList.css"

const InquiryBoard = ({dataType, pageType}) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    const dispatch = useDispatch();
    const boardState = useSelector((state) => state.board)
    const { data, error } = boardState;

    // 페이지네이션
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태
    const itemsPerPage = 5; // 페이지당 항목 수 설정

    const [email, setEmail] = useState();

    useEffect(() => {
        dispatch(InquiryThunk(dataType));
        if (localStorage.getItem("authorization") === null) {
            sessionStorage.setItem("openSidebarAfterRedirect", true);
            window.location.href = "/"
        }
    }, [dispatch, dataType]);

    // 페이지 계산
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    // 페이지 변경 핸들러
    const handlePageChange = (pageNumber) => {
        console.log("Page changed to:", pageNumber);
        setCurrentPage(pageNumber)
    };
    
    const handleOnClick = () => {

    }


    return (
        <>
            <div className="row mb-lg-5 mb-xl-0">
                <div className="col-lg-8 col-xl-9 mx-auto">

                    <h6 className="font-weight-medium font-size-7 font-size-xs-25 text-center mt-8">내 문의 목록</h6>
                    <div className="text-right pb-2">
                        <Link to="/inquirySend" onClick={handleOnClick} className="h-primary d-block" >1:1 문의하기 <i className="glyph-icon flaticon-next"></i></Link>
                    </div>

                    <div className="d-md-flex align-items-center p-3 bg-punch-light board-header">
                        <div className="mr-md-2 col-xl-9">
                            <span className="font-size-2 text-secondary-black-200">제목</span>
                        </div>
                        <div className="font-size-2 text-secondary-black-200 mr-md-5 col-xl-2 ">
                            <span>문의일</span>
                        </div>
                    </div>

                    {currentItems && currentItems.map((datalist, index) => (

                        <div className="d-md-flex align-items-center p-3 border-bottom" key={index}>
                            <div className="mr-md-2 col-xl-9">
                                <Link className="font-size-2 text-secondary-gray-700" to='/boardDetail'
                                      state={{data: datalist}}>{datalist.title}</Link>
                            </div>
                            <div className="font-size-2 text-secondary-gray-700 mr-md-5 col-xl-2 ">
                                <span>{formatDate(datalist.createdAt)}</span>
                            </div>
                        </div>

                    ))}
                    {currentItems.length === 0 && (
                        <div className="text-center align-items-center p-3 border-bottom">
                            내 문의 내역이 없습니다.
                        </div>
                    )}
                </div>
            </div>

            <nav aria-label="Page navigation example" className="mt-8 mb-8">
                <Pagination
                    activePage={currentPage}
                    items={itemsPerPage} // 페이지당 항목수
                    itemsCount={data.length}
                    pageDisplay={5}
                    onPageChange={handlePageChange}
                />
            </nav>
        </>
    )
}

export default InquiryBoard;