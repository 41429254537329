import React, {useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import store from "../../store/store";
import {loginAction} from "../../store/action/AuthAction";
import { Link } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT;

const LoginInput = () => {
    const { register, handleSubmit, formState: { errors }  } = useForm();
    const [sendEmailRetry, setSendEmailRetry] = useState(false);

    const onSubmit = async data => {
        const formData = new FormData();
        // Object.keys(data).forEach(key => {
        //     formData.append(key, encrypt(data[key]));
        // });
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        axios({
            method: 'post',
            url: apiUrl + '/user/public/login',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            //withCredentials: true,

        }).then((response) => {
            // alert(response.data + '로그인 성공')
            const authorization  = response.data;
            localStorage.setItem("authorization", authorization)
            store.dispatch(loginAction(authorization)); // redux 상태 업데이트

            window.location.replace("/")

        }).catch((error) => {
            console.log(error)
            console.log(error.message)
            // throw error;
            const statusCode = error.response ? error.response.status : error.status;

            switch (statusCode) {
                case 403:
                    alert("아이디/비밀번호를 확인해주세요");
                    break;
                case 400:
                    alert("이메일에서 인증을 진행해주세요");
                    setSendEmailRetry(true);
                    break;
                case 410:
                    alert("탈퇴한 계정입니다.")
                    break;
                default:
                    alert("SERVER ERROR:", error.status)
            }
        });
    }

    const onSendCode = (data) => {
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });
        axios({
            method: 'put',
            url: apiUrl + '/user/public/auth/retry',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then((response) => {
            if(response.data){
                alert("인증링크가 재전송 되었습니다.")
            }
        }).catch((error) => {
            console.log(error)
            console.log(error.message)
            const statusCode = error.response ? error.response.status : error.status;
            switch (statusCode) {
                case 403:
                    alert("가입 정보가 없는 이메일입니다.")
                    break;
                default:
                    alert("SERVER ERROR:", error.status)
            }
        });
    }

    return (
        <form className="">
            <div id="login" data-target-group="idForm">
                <header className="border-bottom px-4 px-md-6 py-4">
                    <h2 className="font-size-3 mb-0 d-flex align-items-center"><i
                        className="flaticon-user mr-3 font-size-5"></i>로그인</h2>
                </header>

                <div className="p-4 p-md-6">
                    <div className="form-group mb-4">
                        <div className="js-form-message js-focus-state">
                            <label id="signinEmailLabel" className="form-label"
                                   htmlFor="signinEmail">이메일</label>
                            <input type="email"
                                   className="form-control rounded-0 height-4 px-4"
                                   name="userEmail" id="userEmail"
                                   placeholder="user@pandori.com"
                                   aria-label="user@pandori.com"
                                   aria-describedby="signinEmailLabel" required
                                   {...register("userEmail")} />
                        </div>
                    </div>

                    <div className="form-group mb-4">
                        <div className="js-form-message js-focus-state">
                            <label id="signinPasswordLabel" className="form-label"
                                   htmlFor="signinPassword">비밀번호</label>
                            <input type="password"
                                   className="form-control rounded-0 height-4 px-4"
                                   name="userPwd" id="userPwd" placeholder=""
                                   aria-label="" aria-describedby="signinPasswordLabel"
                                   required {...register("userPwd")}/>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mb-5 align-items-center">
                        <div className="js-form-message">
                            {/*    <div*/}
                            {/*        className="custom-control custom-checkbox d-flex align-items-center text-muted">*/}
                            {/*        <input type="checkbox" className="custom-control-input"*/}
                            {/*               id="termsCheckbox" name="termsCheckbox" required/>*/}
                            {/*        <label className="custom-control-label"*/}
                            {/*               htmlFor="termsCheckbox">*/}
                            {/*<span className="font-size-2 text-secondary-gray-700">*/}
                            {/*    Remember me*/}
                            {/*</span>*/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                        </div>

                        <Link className="js-animation-link text-dark font-size-2 t-d-u link-muted font-weight-medium"

                              data-target="#forgotPassword"
                              data-link-group="idForm"
                              data-animation-in="fadeIn">비밀번호 찾기</Link>
                    </div>

                    <div className="mb-4d75">
                        <button type="submit" onClick={handleSubmit(onSubmit)}
                                className="btn btn-block py-3 rounded-0 btn-dark">로그인
                        </button>
                    </div>

                    <div className="mb-2">
                        <Link
                            className="js-animation-link btn btn-block py-3 rounded-0 btn-outline-dark font-weight-medium"
                            data-target="#signup"
                            data-link-group="idForm"
                            data-animation-in="fadeIn">회원가입</Link>
                    </div>

                    {sendEmailRetry && (
                        <div className="text-center mb-4">
                            <span className="small text-muted">인증링크가 오지 않았나요?</span>
                            <Link className="js-animation-link small" role="button" onClick={handleSubmit(onSendCode)}
                                  data-target="#login"
                                  data-link-group="idForm"
                                  data-animation-in="fadeIn"> 재전송
                            </Link>
                        </div>
                    )}

                </div>
            </div>
        </form>
    )
}

export default LoginInput