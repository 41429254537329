import React, { useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {BoardThunk} from "../../store/reducer/BoardThunk";
import DOMPurify from 'dompurify'

// 이용약관, 개인정보처리방침, 회사소개 - 1pages board 동일 UI
const BoardGeneric = ({ dataType }) => {
    const dispatch = useDispatch();
    const boardState = useSelector((state) => state.board)
    const { data, error } = boardState;

    useEffect(() => {
        dispatch(BoardThunk(dataType));
    }, [dispatch, dataType]);

    const sanitizedDescription = (description) => {
        return DOMPurify.sanitize(description);
    }

    return (
        <div className="container">
            <div className="space-bottom-1 space-bottom-lg-2 space-bottom-xl-3">
                <div className="pb-lg-4">
                    {(data.length === 0) && (<div className="text-center pt-12">작성된 게시글이 없습니다.</div>)}
                    {data &&
                        data.map((datalist, index) => (
                        <>
                            <div className="py-4 py-lg-5 py-xl-8" key={index}>
                                <h6 className="font-weight-medium font-size-7 font-size-xs-25 text-center">{datalist.title}</h6>
                            </div>
                            <div style={{wordBreak: "break-word"}} dangerouslySetInnerHTML={{__html: sanitizedDescription(datalist.content)}}>
                            </div>
                        </>))}
                </div>
            </div>
        </div>

        )
}

export default BoardGeneric;