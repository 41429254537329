import React, {useEffect, useState} from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import Pagination from "../Pagination";
import DefaultImage from "../../assets/image/noimage.jpg";
import LoadingBar from "../common/LoadingBar";

const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT

const SpecialList = () => {
    const navigate = useNavigate();
    const [dataType, setDataType] = useState(9);
    const [dataList, setDataList] = useState([]);
    const maxLength = 20; // list content 최대 길이
    const specialCountPerPage = 6
    const [currentPage, setCurrentPage] = useState(1);
    const [pageInfo, setPageInfo] = useState({
        activePage: currentPage,
        itemsCountPerPage: specialCountPerPage,
        itemCount: 0,
        pageDisplay: 5,
        onPageChange: setCurrentPage
    });

    const [loading, setLoading] = useState(true)
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }
    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl  + '/common/public/board/list',
                {params:
                        {
                            type: dataType,
                            currentPage: currentPage,
                            boardCountPerPage: specialCountPerPage,
                        }} );
            setDataList(response.data);
            const specialData = response.data;
            const pageInfo = {
                activePage: currentPage,
                itemsCountPerPage: specialCountPerPage,
                itemCount: specialData[0].totalCount,
                pageDisplay: 5,
                onPageChange: (page) => setCurrentPage(page)
            }
            setPageInfo(pageInfo)
        } catch (error) {
            console.error('Error ', error);
        } finally {
            setLoading(false)
        }
    };
    useEffect(() => {
        fetchData();

    }, [dataType, currentPage]);

    useEffect(() => {
        if(currentPage !== 1) {
            setCurrentPage(1)
        } else {
            fetchData();
        }
    }, [dataType]);

    const handleTabClick = (type) => {
        setDataType(type);
    };

    const imageErrorHandler = (e) => {
        e.target.src = DefaultImage
    }

    const handleLinkClick = (data) => {
        navigate('/specialDetail', { state: { data: data , name:data.title} });
    }
    
    // 내용
    const boardContent = (htmlContent) => {
        // HTML 태그 제거
        const noTagContent = htmlContent.replace(/<[^>]*>/g, '');
        const entitiesDel = noTagContent.replace(/&[^;]+;/g, '');
        const contentText = entitiesDel.replace(/\s+/g, ' ').trim();
        return contentText.length > maxLength
            ? contentText.slice(0, maxLength) + "..."
            : contentText;
    }

    if(loading) {
        return (
            <div style={{height: '100vh', display: 'block'}}>
                <LoadingBar/>
            </div>
        )
    } else {
        return(

            <>
                <div className="mb-5 mb-lg-8">
                    <div className="mb-5">
                        <div className="container">
                            {/* 상단 메뉴바 */}
                            <ul className="nav justify-content-md-center nav-gray-700 mb-5 flex-nowrap flex-md-wrap overflow-auto overflow-md-visible"
                                id="featuredBooks" role="tablist">
                                <li className="nav-item mx-5 mb-1 flex-shrink-0 flex-md-shrink-1">
                                    <Link className={`nav-link px-0 ${dataType === 9 ? 'active' : ''}`}
                                          id="featured-tab" data-toggle="tab"
                                          role="tab" aria-controls="featured"
                                          aria-selected={dataType === 9 ? 'true' : 'false'}
                                          onClick={() => handleTabClick(9)} >
                                        All
                                    </Link>
                                </li>
                                <li className="nav-item mx-5 mb-1 flex-shrink-0 flex-md-shrink-1">
                                    <Link className={`nav-link px-0 ${dataType === 7 ? 'active' : ''}`}
                                          id="featured-tab" data-toggle="tab"
                                          role="tab" aria-controls="featured"
                                          aria-selected={dataType === 7 ? 'true' : 'false'}
                                          onClick={() => handleTabClick(7)} >
                                        특집
                                    </Link>
                                </li>
                                <li className="nav-item mx-5 mb-1 flex-shrink-0 flex-md-shrink-1">
                                    <Link className={`nav-link px-0 ${dataType === 8 ? 'active' : ''}`}
                                          id="featured-tab" data-toggle="tab"
                                          role="tab" aria-controls="featured"
                                          aria-selected={dataType === 8 ? 'true' : 'false'}
                                          onClick={() => handleTabClick(8)} >
                                        이벤트
                                    </Link>
                                </li>
                            </ul>


                            <div className="tab-content">
                                {/* list */}
                                <div className="tab-pane fade show active" id="featured" role="tabpanel"
                                     aria-labelledby="featured-tab">
                                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                                        {!dataList && dataList.length === 0 && (<div className="mb-5 mt-5 text-center">작성된 게시글이 없습니다.</div>)}
                                        {dataList.map((data, index) => (
                                            <div className="col"  key={index}>
                                                <div className="mb-5 mt-5" style={{maxHeight: '400px'}}>
                                                    <Link className="d-block mb-3 text-center" style={{height:''}}
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              handleLinkClick(data);}}
                                                    >

                                                        <div style={{height: '280px'}} className="align-content-center mb-5">
                                                            <img className="img-fluid"
                                                                 style={{maxHeight: '300px'}}
                                                                 src={!data.boardFileUrl ? DefaultImage : data.boardFileUrl}
                                                                 onError={imageErrorHandler}
                                                            />
                                                        </div>

                                                    </Link>
                                                    <div className="mb-2">
                                                        <Link className="font-size-2" to=""
                                                              onClick={() => handleTabClick(data.type)}>{data.type === 7 ? "특집" : "이벤트"}</Link>
                                                    </div>
                                                    <h6 className="font-size-26 crop-text-2 font-weight-medium text-lh-1dot4">
                                                        <Link onClick={(e) => {
                                                            e.preventDefault();
                                                            handleLinkClick(data);}} >{data.title}</Link>
                                                    </h6>
                                                    <div className="font-size-2 text-secondary-gray-700">
                                                        {boardContent(data.content)}
                                                    </div>
                                                    <div className="font-size-2 text-secondary-gray-700">
                                                        <span>{formatDate(data.createdAt)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <Pagination
                        activePage={pageInfo.activePage}
                        items={pageInfo.itemsCountPerPage} // 페이지당 항목수
                        itemsCount={pageInfo.itemCount}
                        pageDisplay={pageInfo.pageDisplay}
                        onPageChange={pageInfo.onPageChange}
                    />
                </div>

            </>
        )
    }
}

export default SpecialList;