import React, {useEffect} from 'react';
import {GlobalNavbar, Sidebar} from "./Headers";
import {LoginSidebar} from "../user";
import { useLocation } from 'react-router-dom';
import {Navgationbar} from "./index";
import $ from 'jquery'
import HSUnfold from "../HS/HSUnfold";
import HSShowAnimation from "../HS/HSShowAnimation";


const Header = ({onPageChange}) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const hideNavbar = ['/', '/home', '/notice', '/aboutUs', '/usageTerms', '/privacyPolicy', '/boardDetail'].includes(currentPath);

    useEffect(() => {
        HSUnfold.init($('[data-unfold-target]'))
        HSShowAnimation.init('.js-animation-link');
    }, []);

    return (
        <>
        <header id="site-header" className="site-header__v1 position-fixed bg-white" style={{zIndex:1000, width: '100%', top: '0'}}>
            <GlobalNavbar onPageChange={onPageChange}/>
            {!hideNavbar && <Navgationbar pageName={currentPath}/>}
        </header>
            <Sidebar onPageChange={onPageChange}/>
            <LoginSidebar onPageChange={onPageChange}/>
        </>
    );
};

export default Header;