import React, {useState} from "react";
import {LoginInput, JoinInput, ForgotPassword, LoginInputMobile, JoinInputMobile, ForgotPasswordMobile} from "./"
import LoadingBar from "../common/LoadingBar";

const LoginSidebar = () => {
    const [isLoading, setIsLoading] = useState(false)
    return(
        <>
            {/* 모바일 */}
            <aside id="sidebarContent9" className="u-sidebar u-sidebar__lg" aria-labelledby="sidebarNavToggler9">
                <div className="u-sidebar__scroller">
                    <div className="u-sidebar__container">
                        <div className="u-header-sidebar__footer-offset">
                            {/* 로그인 상단 헤더 - 모바일*/}
                            <div
                                className="d-flex align-items-center position-absolute top-0 right-0 z-index-2 mt-5 mr-md-6 mr-4">
                                <button type="button" className="close ml-auto"
                                        aria-controls="sidebarContent9"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        data-unfold-event="click"
                                        data-unfold-hide-on-scroll="false"
                                        data-unfold-target="#sidebarContent9"
                                        data-unfold-type="css-animation"
                                        data-unfold-animation-in="fadeInRight"
                                        data-unfold-animation-out="fadeOutRight"
                                        data-unfold-duration="500">
                                    <span aria-hidden="true">닫기 <i className="fas fa-times ml-2"></i></span>
                                </button>
                            </div>

                            <div className="js-scrollbar u-sidebar__body">
                                <div className="u-sidebar__content u-header-sidebar__content">

                                    <LoginInputMobile/>

                                    <JoinInputMobile setIsLoading={setIsLoading}/>

                                    <ForgotPasswordMobile setIsLoading={setIsLoading}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
            {/* PC */}
            <aside id="sidebarContent" className="u-sidebar u-sidebar__lg" aria-labelledby="sidebarNavToggler">
                <div className="u-sidebar__scroller">
                    <div className="u-sidebar__container">
                        <div className="u-header-sidebar__footer-offset">
                            {/* 로그인 상단 헤더 - PC*/}
                            <div
                                className="d-flex align-items-center position-absolute top-0 right-0 z-index-2 mt-5 mr-md-6 mr-4">
                                <button type="button" className="close ml-auto"
                                        aria-controls="sidebarContent"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        data-unfold-event="click"
                                        data-unfold-hide-on-scroll="false"
                                        data-unfold-target="#sidebarContent"
                                        data-unfold-type="css-animation"
                                        data-unfold-animation-in="fadeInRight"
                                        data-unfold-animation-out="fadeOutRight"
                                        data-unfold-duration="500">
                                    <span aria-hidden="true">닫기 <i className="fas fa-times ml-2"></i></span>
                                </button>
                            </div>

                            <div className="js-scrollbar u-sidebar__body">
                                <div className="u-sidebar__content u-header-sidebar__content">

                                    <LoginInput/>

                                    <JoinInput setIsLoading={setIsLoading}/>

                                    <ForgotPassword setIsLoading={setIsLoading}/>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading && <div style={{width: '100%', display: 'block'}}>
                    <LoadingBar/>
                </div>}
            </aside>
        </>
    )
}

export default LoginSidebar