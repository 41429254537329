import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import axios from 'axios';
import axiosInstanceWithToken from "../../containers/axiosInstanceWithToken";
import DOMPurify from 'dompurify'
import ImageModal from "./ImageModal";
import DefaultImage from "../../assets/image/noimage.jpg";
import './sideMenu.css';
import './mobileTable.css'
const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT;

const AlbumDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { albumCode } = useParams();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const maxLength = 20; // 수록곡명 최대 길이
    const albumName = location.state?.name || ''; // 홈 -> 메인 상단 배너 : 앨범 이름 설정

    // 상태 변수 설정
    const [album, setAlbum] = useState({});
    const [minPrice, setMinPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedTracks, setSelectedTracks] = useState([]);
    const [selectedArtwork, setSelectedArtwork] = useState(null);
    const [selectedArtworkUrl, setSelectedArtworkUrl] = useState(''); // modal url
    const [selectedArtworkComment, setSelectedArtworkComment] = useState(''); // modal comment
    const [allTracks, setAllTracks] = useState({});
    const [allArtwork, setAllArtwork] = useState({});
    const [allOrderProduct, setAllOrderProduct] = useState(false);
    const [selectedRadio, setSelectedRadio] = useState('each');
    const [isDisabled, setIsDisabled] = useState(false); // 모든 곡 구매 시 아트워크 관련
    const [display, setDisplay] = useState(false); // display null 판매불가 상품 처리
    const [playIndex, setPlayIndex] = useState(-1); // 미리듣기
    const [isOpen, setIsOpen] = useState(false); // 이미지 모달

    // 스크롤 이동
    const section1 = useRef(null);
    const section2 = useRef(null);
    const section3 = useRef(null);
    const [activeSection, setActiveSection] = useState('');

    const scrollTosection = (e, ref) => {
        e.preventDefault();
        if (ref && ref.current) {
            const topOffset = ref.current.getBoundingClientRect().top + window.pageYOffset - 200; // 200px 위로
            window.scrollTo({
                top: topOffset,
                behavior: "smooth"
            });
        }
    };

    // Observer 설정
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setActiveSection(entry.target.id); // 섹션이 보일 때 상태 변경
                    }
                });
            },
            {
                rootMargin: '-200px 0px 0px 0px',
                threshold: 0.1, // 요소의 10%가 보일 때 트리거
            }
        );
        const sections = [section1.current, section2.current, section3.current];
        sections.forEach(section => {
            if (section) {
                observer.observe(section); // 옵저버 등록
            }
        });
        return () => {
            sections.forEach(section => {
                if (section) {
                    observer.unobserve(section); // 옵저버 해제
                }
            });
        };
    }, []);

    // 데이터 불러오기 및 설정
    useEffect(() => {
        const gnbHeight = document.querySelector('#site-header').offsetHeight; // GNB 높이 가져오기
        const contentHeader = document.querySelector('.content-header');
        contentHeader.style.top = `${gnbHeight}px`; // top 속성 설정

        const axiosInstance = isAuthenticated ? axiosInstanceWithToken : axios;

        // 앨범 데이터 불러오기
        axiosInstance.get(`${apiUrl}/product/public/album`, { params: { albumCode } })
            .then(response => {
                const albumData = isAuthenticated ? response : response.data; // 로그인 여부에 따라 데이터 처리
                setAlbum(albumData);

                // 전시 여부 확인
                if (!albumData.display) {
                    setDisplay(true);
                }

                // 네비게이션 바 앨범 이름 설정
                if (albumName === '') {
                    navigate(`/albumDetail/${albumCode}`, {
                        state: { from: "/", name: albumData.albumName }
                    });
                }

                // 전체 상품 구매 여부 확인
                if(albumData.trackordercount === albumData.trackCount && albumData.artworkordercount === 1) {
                    setAllOrderProduct(true);
                }

                if (albumData.tracks) {
                    const trackPrices = albumData.tracks.map(item => item.trackPrice);
                    const artworkPrice = albumData.artworks.artworkPrice;
                    const minTrackPrice = Math.min(...trackPrices);
                    const minimumPrice = Math.min(minTrackPrice, artworkPrice);
                    setMinPrice(minimumPrice.toLocaleString());
                }
            })
            .catch(error => {
                console.error(error);
            });
    }, [albumCode, albumName, isAuthenticated]);

    // 트랙 체크박스 handler
    const handleTrackCheckboxChange = (e, track) => {
        const { checked } = e.target;
        setAllTracks(prevCheckedItems => {
            const updatedCheckedItems = {
                ...prevCheckedItems,
                [track.trackCode]: track.ordering ? false : checked // 이미 구매한 트랙 제외
            };

            // 체크된 트랙 수
            const checkedCount = Object.values(updatedCheckedItems).filter(value => value).length;
            const totalTracks = album.trackCount + (album.trackOrderCount ? album.trackOrderCount : 0);

            // 전체 선택 여부 확인
            const allChecked = totalTracks === checkedCount;
            // 전체 선택 시 라디오 버튼 처리
            if (allChecked) {
                setSelectedRadio('all');
                const updatedArtworkItems = { ...allArtwork };
                updatedArtworkItems[album.artworks.artworkGroupCode] = true; // 아트워크 선택
                setAllArtwork(updatedArtworkItems);
                setSelectedArtwork(album.artworks); // 선택된 아트워크 업데이트
                setIsDisabled(true);
                setTotalPrice(album.albumPrice);
            } else {
                setSelectedRadio('each');
                setIsDisabled(false);
            }

            return updatedCheckedItems; // 상태 반환
        });

        // 선택된 트랙 업데이트
        setSelectedTracks(prevSelectedTracks => {
            const updatedTracks = checked
                ? [...prevSelectedTracks, track]
                : prevSelectedTracks.filter(item => item.trackCode !== track.trackCode);

            const totalTrackPrice = updatedTracks.reduce((acc, item) => acc + item.trackPrice, 0);
            const totalArtworkPrice = selectedArtwork ? selectedArtwork.artworkPrice : 0;

            setTotalPrice(totalTrackPrice + totalArtworkPrice); // 총 가격 업데이트
            return updatedTracks; // 상태 반환
        });
    };

    // 아트워크 체크박스 handler
    const handleArtworkCheckboxChange = (e, artworkGroup) => {
        const { checked } = e.target;
        setAllArtwork({
            [artworkGroup.artworkGroupCode]: checked, // 체크 상태 업데이트
        });
        const totalArtworkPrice = checked ? artworkGroup.artworkPrice : 0;
        const totalTrackPrice = selectedTracks.reduce((acc, item) => acc + item.trackPrice, 0);

        setSelectedArtwork(checked ? artworkGroup : null); // 선택된 아트워크 업데이트
        setTotalPrice(totalArtworkPrice + totalTrackPrice); // 총 가격 업데이트
    };

    // 낱개 선택 처리
    const handleEachSelect = () => {
        setSelectedTracks([]); // 선택된 트랙 초기화
        setSelectedArtwork(null); // 선택된 아트워크 초기화

        const updatedTracks = {};
        const updatedArtworks = {};
        Object.keys(allTracks).forEach(trackCode => {
            updatedTracks[trackCode] = false; // 모든 트랙 초기화
        });
        if (album.artworks) {
            if (!album.artworks.ordering) {
                updatedArtworks[album.artworks.artworkGroupCode] = false; // 아트워크 초기화
                document.getElementById(album.artworks.artworkGroupCode).disabled = false; // 아트워크 활성화
            }
        }
        setAllTracks(updatedTracks);
        setAllArtwork(updatedArtworks);
        setTotalPrice(0); // 총 가격 초기화
    };

    // 전체 선택 처리
    const handleAllSelect = () => {
        let price = album.albumPrice; // 앨범 가격
        let isOrdering = false;
        let trackPrice = 0;

        if (album.tracks) {
            const updatedCheckedItems = { ...allTracks };
            const selectedTracks = [];

            album.tracks.forEach(track => {
                if (!track.ordering) {
                    updatedCheckedItems[track.trackCode] = true; // 트랙 선택
                    selectedTracks.push(track); // 선택된 트랙 목록에 추가
                    trackPrice += track.trackPrice;
                }else{
                    isOrdering = true;
                }
            });
            setSelectedTracks(selectedTracks); // 선택된 트랙 업데이트
            setAllTracks(updatedCheckedItems); // 트랙 상태 업데이트
        }

        if (album.artworks) {
            if (!album.artworks.ordering) {
                const updatedCheckedItems = { ...allArtwork };
                updatedCheckedItems[album.artworks.artworkGroupCode] = true; // 아트워크 선택
                setAllArtwork(updatedCheckedItems);
                document.getElementById(album.artworks.artworkGroupCode).disabled = true; // 아트워크 비활성화
                setSelectedArtwork(album.artworks); // 선택된 아트워크 업데이트
            }
        }

        if(isOrdering){
            const totalTrackPrice = trackPrice;
            const totalArtworkPrice = selectedArtwork ? selectedArtwork.artworkPrice : 0;
            price = (totalTrackPrice + totalArtworkPrice); // 총 가격 업데이트
        }
        setTotalPrice(price);
        setIsDisabled(true); // 전체 선택 시 비활성화
    };

    // 라디오 버튼 선택 처리
    const handleRadioChange = (e) => {
        setSelectedRadio(e.target.value);
    }

    // 오디오 관련
    const handlePreviewPlay = (id, index) => {
        const audios = document.getElementsByTagName('audio');

        // 오디오를 멈추고 초기화
        Array.from(audios).forEach(audio => {
            if (audio.id !== id) {
                audio.pause();
                audio.currentTime = 0; // 현재 시간 초기화
            }
        });

        // 이전에 재생된 아이콘 변경
        if (playIndex >= 0) {
            const previousPlayIcon = document.getElementById("volumeIcon" + playIndex);
            previousPlayIcon.classList.replace('fa-volume-up', 'fa-volume-off'); // 아이콘 상태 변경
        }

        const audio = document.getElementById(id);
        const volumeIcon = document.getElementById("volumeIcon" + index);

        // 오디오 재생 상태에 따라 아이콘 및 상태 변경
        if (audio.paused) {
            audio.play(); // 오디오 재생
            volumeIcon.classList.replace('fa-volume-off', 'fa-volume-up'); // 아이콘 상태 변경
            setPlayIndex(index); // 현재 재생 인덱스 설정
        } else {
            audio.pause(); // 오디오 일시 정지
            audio.currentTime = 0; // 현재 시간 초기화
            volumeIcon.classList.replace('fa-volume-up', 'fa-volume-off'); // 아이콘 상태 변경
            setPlayIndex(-1); // 재생 인덱스 초기화
        }
    };

    // 이미지 팝업
    const modalOpen = (url, comment) => {
        setSelectedArtworkUrl(url);
        setSelectedArtworkComment(comment);
        setIsOpen(true); // 이미지 팝업 열기
    };

    const imageErrorHandler = (e) => {
        e.target.src = DefaultImage; // 이미지 오류 처리
    };

    // 장바구니 담기
    const basketAdd = () => {
        const trackCheck = selectedTracks.map(item => item.trackCode);
        const artworkGroupCode = selectedArtwork ? selectedArtwork.artworkGroupCode : '';
        let artworkCheck = [];
        if (artworkGroupCode && artworkGroupCode !== '') {
            artworkCheck = album.artworks.artworkList.map(e => e.artworkCode);
        }
        const productIds = [...trackCheck, ...artworkCheck]
        if (productIds.length === 0) {
            alert('상품을 선택해 주세요');
            return;
        }
        if (!isAuthenticated) {
            alert('로그인이 필요합니다');
            return;
        }
        axiosInstanceWithToken.post(apiUrl + '/basket/add', { 'productIds': productIds })
            .then(response => {
                if (response) {
                    dispatch({ type: 'UPDATE'});
                    let result = window.confirm("장바구니 담기 성공! 장바구니로 이동하시겠습니까?");
                    if (result) {
                        window.location.href = "/basket";
                    }
                } else {
                    alert("장바구니 담기 실패. 다시 시도해 주세요");
                }
            })
            .catch(error => {
                alert("장바구니 담기 실패. 다시 시도해 주세요");
            });
    };

    // 말풍선
    const tooltip = document.getElementById('tooltip');
    document.querySelectorAll('td').forEach(td => {
        td.addEventListener('mouseenter', (e) => {
            const message = e.target.getAttribute('data-tooltip');
            if (message) {
                tooltip.textContent = message;
                tooltip.style.display = 'block';
                tooltip.style.opacity = "1";
            }
        });

        td.addEventListener('mousemove', (e) => {
            let top = e.clientY + 10; // 마우스 아래쪽에 말풍선 위치
            let left = e.clientX + 10; // 마우스 오른쪽에 말풍선 위치
            tooltip.style.top = `${top}px`;
            tooltip.style.left = `${left}px`;
        });

        td.addEventListener('mouseleave', () => {
            tooltip.style.display = 'none';
            tooltip.style.opacity = "0";
        });
    });

    // HTML
    const sanitizedDescription = (description) => {
        return DOMPurify.sanitize(description);
    };

    // 사이즈 단위 계산
    const calcSize = (size) => {
        return size < 1024 * 1024
            ? `${Math.floor(size / 10.24) / 100}KB`
            : `${Math.floor(size / (1024 * 10.24)) / 100}MB`;
    };


    return (
        <div id="primary" className="content-area">
            <div id="main" className="site-main ">
                <div className="product" style={{overflow: 'auth'}}>

                    {/* 상단 구성 */}
                    <div className="container border-bottom">
                        <div className="row">
                            <div
                                className="col-md-5 woocommerce-product-gallery woocommerce-product-gallery--with-images images ">
                                <figure className="woocommerce-product-gallery__wrapper pt-8 mb-0 pl-5 pr-5 text-center">
                                    <img src={album.coverUrl ? album.coverUrl : DefaultImage} alt="Image Description"
                                         height="400px" width="400px"
                                         onError={imageErrorHandler}
                                         className=" img-fluid"/>
                                </figure>
                            </div>

                            <div className="col-md-7 pl-0 summary entry-summary border-left">
                                <div className="space-top-2 px-4 px-xl-7 border-bottom">
                                    {display &&
                                        <h1 className="product_title entry-title font-size-7 mb-4 text-red-1">판매하지 않는
                                            상품입니다.</h1>}
                                    <h1 className="product_title entry-title font-size-7 mb-3">{album.albumName}</h1>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="font-size-2 mb-5 mt-4">
                                                <span className="font-weight-medium text-gray-600">아티스트&ensp;</span>
                                                <span className="ml-3">{album.albumArtist}</span>
                                            </div>
                                            <div className="font-size-2 mb-5">
                                                <span className="font-weight-medium text-gray-600">발매사&ensp;</span>
                                                <span className="ml-3">{album.publisher}</span>
                                                <span className="ml-3">( {album.releaseDate} 발매)</span>
                                            </div>

                                        </div>

                                        <div className="col-md-6 ">
                                        </div>
                                        <div className="col-md-12 justify">
                                            <div className="font-size-2 mb-4">
                                                <p className="text-gray-600">구성</p>
                                                <p className="ml-3">곡
                                                    <strong> &ensp; {album.tracks && album.tracks[0].trackName} &ensp; </strong>
                                                    <span
                                                        className="text-gray-600">({album.tracks && album.tracks[0].trackFileFormat}) </span>
                                                    &ensp;외 {album.trackCount ? (album.trackCount - 1) : 0}개
                                                </p>
                                                {album.artworks && album.artworks.artworkList[0] &&
                                                    <p className="ml-3">아트워크
                                                        <strong> &ensp; {album.artworks.artworkList[0].artworkName} &ensp; </strong>
                                                        <span
                                                            className="text-gray-600">({album.artworks.artworkList[0].artworkFileFormat}) </span>
                                                        &ensp;외 {album.artworks && album.artworks.artworkList.length - 1}개
                                                    </p>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="align-items-center">
                                    <div className="row">
                                        <div className="col-md-12 px-4 py-5 ml-3 px-xl-7 ">
                                            <div className="font-size-2 ">
                                                <p>개별구매 {(minPrice === 0 && album.albumPrice) ? album.albumPrice.toLocaleString() : minPrice} ~ {album.albumPrice > 0 && album.albumPrice.toLocaleString()} 원</p>
                                                <p>앨범할인 - {(album.artworks && album.artworks.artworkPrice) ? album.artworks.artworkPrice.toLocaleString() : 0} 원</p>
                                                <span className="text-red-1">* 모든 곡 구매 시 아트워크 가격이 할인됩니다.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 오른쪽 사이드바 */}
                    {!display && (
                        <>
                            <div className="side-menu">
                                <div className="border-bottom">
                                    <div className="font-size-2 pb-3">
                                        <input type="radio" id="allSelect" name="albumRadio"
                                               onClick={handleAllSelect}
                                               onChange={handleRadioChange}
                                               value='all'
                                               checked={selectedRadio === 'all'}
                                               disabled={display || allOrderProduct}
                                        />
                                        <label htmlFor="allSelect" className="pl-3">전체 구매</label>
                                        <input className="ml-3" type="radio" id="eachSelect" name="albumRadio"
                                               value='each'
                                               onClick={handleEachSelect}
                                               onChange={handleRadioChange}
                                               checked={selectedRadio === 'each'}
                                               disabled={display || allOrderProduct}
                                        />
                                        <label htmlFor="eachSelect" className="pl-3">낱개 구매</label>
                                    </div>

                                    <div className="pb-1 font-size-2">
                                        <p className="mb-2">선택 한 곡 총 <strong>{selectedTracks.length}</strong> 개</p>
                                        <p className="">아트워크 총 <strong>{selectedArtwork ? selectedArtwork.artworkList.length : 0}</strong> 개</p>
                                    </div>
                                </div>

                                <div className="border-bottom"
                                     style={{overflow: "auto", height: '160px'}}>
                                    <div className="mt-4 pb-3 font-size-2">

                                        {(selectedTracks.length === 0 && !selectedArtwork ) && (
                                            <p>곡과 아트워크에서 구매하실 작품을 선택해 주세요</p>
                                        )}

                                        {selectedTracks.length !== 0 && selectedTracks.map((tracks) => {
                                            return (
                                                <div key={tracks.trackCode}>
                                                    <p>
                                                        <span className="mr-3">{tracks.trackName}</span>
                                                    </p>
                                                </div>
                                            )
                                        })}
                                        {selectedArtwork && selectedArtwork.artworkList && selectedArtwork.artworkList.map((artwork) => {
                                            return (
                                                <div key={artwork.artworkCode}>
                                                    <p>
                                                        <span className="mr-3">{artwork.artworkName}</span>
                                                    </p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div className="pt-3 " style={{width: '100%'}}>
                                    <div className="pad-total">
                                        <strong className="woocommerce-Price-amount amount">금액
                                            : {totalPrice && totalPrice.toLocaleString()}원</strong>
                                        <p className="font-size-2">(VAT 포함)</p>
                                    </div>
                                </div>
                                <div className="pad-inline-block">
                                    <div className="col-md-4">
                                        <div className="mr-3 content-centered-x">
                                            {allOrderProduct ? (
                                                <button name="add-to-cart" value="7145"
                                                        className="btn btn-dark border-0 rounded-0 p-3 single_add_to_cart_button button alt"
                                                        style={{minWidth: '14rem'}}
                                                        disabled="disabled"
                                                >
                                                    구매 완료 상품
                                                </button>
                                            ) : (
                                                <button type="submit" name="add-to-cart" value="7145"
                                                        className="btn btn-dark border-0 rounded-0 p-3 single_add_to_cart_button button alt"
                                                        style={{minWidth: '14rem'}}
                                                        onClick={basketAdd}
                                                        disabled={display}
                                                >
                                                    장바구니 추가
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                    )}


                    {/* 하단 상세 구성 */}
                    <div className="woocommerce-tabs wc-tabs-wrapper mb-10 ">
                        {/* 상단 탭 */}
                        <ul className={`content-header tabs wc-tabs nav justify-content-md-center flex-nowrap flex-md-wrap overflow-auto overflow-md-visble ${!display && 'mr-14'}`}
                            id="pills-tab" role="tablist">
                        <li className="flex-shrink-0 flex-md-shrink-1 nav-item">
                                <Link
                                    className={`py-4 nav-link font-weight-medium ${activeSection === 'section1' ? 'active' : ''} `}
                                    id="album-info-tab"
                                    data-toggle="pill" role="tab" onClick={(e) => scrollTosection(e, section1)}
                                    aria-controls="album-info" aria-selected="true">
                                    앨범 소개
                                </Link>
                            </li>
                            <li className="flex-shrink-0 flex-md-shrink-1 nav-item">
                                <Link
                                    className={`py-4 nav-link font-weight-medium ${activeSection === 'section2' ? 'active' : ''} `}
                                    id="track-info-tab"
                                    data-toggle="pill" role="tab" onClick={(e) => scrollTosection(e, section2)}
                                    aria-controls="track-info" aria-selected="false">
                                    수록 곡
                                </Link>
                            </li>
                            <li className="flex-shrink-0 flex-md-shrink-1 nav-item">
                                <Link
                                    className={`py-4 nav-link font-weight-medium ${activeSection === 'section3' ? 'active' : ''} `}
                                    id="artwork-info-tab"
                                    data-toggle="pill" role="tab" onClick={(e) => scrollTosection(e, section3)}
                                    aria-controls="artwork-info" aria-selected="false">
                                    아트워크
                                </Link>
                            </li>
                        </ul>

                        <div className="tab-content container border-top" id="pills-tabContent">
                            {/* 앨범 소개 */}
                            <div ref={section1} id="section1" className=" pt-5">
                                <div
                                    className="woocommerce-Tabs-panel panel mx-lg-auto entry-content wc-tab tab-pane fade pt-2 show active"
                                    id="" role="tabpanel" aria-labelledby="album-info-tab">

                                    <div className="tab-content font-size-2 container">
                                        <div className="">
                                            <div className={`col-xl-8 ${display ? 'offset-xl-2' : 'offset-xl-1'}`}>
                                                {album.albumDescriptionImageFileUrl && (
                                                    <div
                                                        className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab pt-10">
                                                        <img src={album.albumDescriptionImageFileUrl}
                                                             alt="Image Description" onError={imageErrorHandler}
                                                             className="mx-auto img-fluid"/>
                                                    </div>
                                                )}
                                                <div style={{wordBreak: "break-word", whiteSpace: "pre-wrap"}}
                                                     className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab pt-9 pb-9"
                                                     dangerouslySetInnerHTML={{__html: sanitizedDescription(album.albumDescription)}}>
                                                </div>
                                                {!album.albumDescriptionImageFileUrl && !album.albumDescription && (
                                                    <div className="text-center">
                                                        <p>등록된 앨범 소개문이 없습니다.</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*  수록곡  */}
                            <div ref={section2} id="section2" className="border-bottom pt-5">
                                <div
                                    className="woocommerce-Tabs-panel panel mx-lg-auto entry-content wc-tab tab-pane fade pt-2 show active"
                                    id="" role="tabpanel" aria-labelledby="track-info-tab">

                                    <div className="tab-content font-size-2 container">
                                        <div className="">
                                            <div className={`col-xl-8 ${display ? 'offset-xl-2' : 'offset-xl-1'}`}>
                                                <div
                                                    className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab pt-9">
                                                    <div className="table-responsive mb-4">
                                                        <table className="table table-hover table-borderless">
                                                            <thead>
                                                            <tr className="text-center">
                                                                <th className="width-80">선택</th>
                                                                {/*<th colSpan="2">곡</th>*/}
                                                                <th>곡</th>
                                                                <th className="width-80 mobile-th">미리듣기</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {album && album.tracks ? (
                                                                album.tracks.map((track, index) => {
                                                                    const [format, soundQuality] = track.trackFileFormat.split(' ');
                                                                    return (
                                                                        <tr className="text-center "
                                                                            key={index}>
                                                                            <td className="first-col align-content-center">
                                                                                <li className="custom-control custom-checkbox">
                                                                                    <input type="checkbox"
                                                                                           name="trackCheckbox"
                                                                                           className="custom-control-input"
                                                                                           id={track.trackCode}
                                                                                           checked={allTracks[track.trackCode] || false}
                                                                                           disabled={track.ordering || display}
                                                                                           onChange={(e) => handleTrackCheckboxChange(e, track)}
                                                                                    />
                                                                                    <label
                                                                                        className="custom-control-label"
                                                                                        htmlFor={track.trackCode}>
                                                                                    </label>
                                                                                </li>
                                                                            </td>
                                                                            <td className="center-cols table-row-mobile" data-tooltip={track.trackName}>
                                                                                <div className="container-track">
                                                                                    <div
                                                                                        className=" text-left align-content-center track-item1">
                                                                                        <strong
                                                                                            style={{fontSize: '1rem'}}>
                                                                                            {track.trackName.length > maxLength ? track.trackName.slice(0, maxLength) + " ..." : track.trackName}
                                                                                        </strong>
                                                                                    </div>
                                                                                    <div
                                                                                        className="text-right text-left-mobile track-item2">
                                                                                        {track.trackPrice.toLocaleString()} 원
                                                                                    </div>
                                                                                    <div
                                                                                        className="text-right text-left-mobile text-gray-700 track-item3">
                                                                                            <span
                                                                                                className="mr-3">{format}</span>
                                                                                        <span
                                                                                            className="mr-3">{soundQuality}</span>
                                                                                        <span>{calcSize(track.trackSize)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="last-col">
                                                                                <div className="mt-3">
                                                                                    <button
                                                                                        className="bg-white border-0"
                                                                                        onClick={() => handlePreviewPlay(`preview${index}`, index)}>
                                                                                        <i className="fas fa-volume-off"
                                                                                           id={`volumeIcon${index}`}></i>
                                                                                    </button>
                                                                                    <audio id={`preview${index}`}
                                                                                           controls
                                                                                           src={track.trackPreviewFileUrl}
                                                                                           type="audio/mpeg" hidden/>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })) : (
                                                                <tr>
                                                                    <td colSpan="4" className="text-center">등록된 곡이 없습니다.
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            </tbody>
                                                        </table>
                                                        <div id="tooltip" className="tooltip"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*  아트워크  */}
                            <div ref={section3} id="section3" className="border-bottom pt-5">
                                <div
                                    className="woocommerce-Tabs-panel panel mx-lg-auto entry-content wc-tab tab-pane fade pt-2 show active"
                                    id="" role="tabpanel" aria-labelledby="artwork-info-tab">
                                    <div className="tab-content font-size-2 container">
                                        <div className="">
                                            <div className={`col-xl-8 ${display ? 'offset-xl-2' : 'offset-xl-1'}`}>
                                                <div
                                                    className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab pt-9">
                                                    <div className="table-responsive mb-4">
                                                        <table className="table table-borderless">
                                                            <thead>
                                                            <tr className="text-center">
                                                                <th className="width-80">선택</th>
                                                                <th>아트워크</th>
                                                                <th className="width-80">총 가격</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {album.artworks && album.artworks.artworkList[0] ? (
                                                                <tr className="text-center">
                                                                    <td rowSpan={album.artworks.artworkList.length} >
                                                                        <li className="custom-control custom-checkbox">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="custom-control-input"
                                                                                id={album.artworks.artworkGroupCode}
                                                                                checked={allArtwork[album.artworks.artworkGroupCode] || false}
                                                                                disabled={album.artworks.ordering || isDisabled || display}
                                                                                onChange={(e) => handleArtworkCheckboxChange(e, album.artworks)}
                                                                            />
                                                                            <label
                                                                                className="custom-control-label"
                                                                                htmlFor={album.artworks.artworkGroupCode}></label>
                                                                        </li>
                                                                    </td>
                                                                    <td className="pb-5">
                                                                        <div
                                                                            className="d-flex align-items-center justify-content-between ">
                                                                            {album.artworks && album.artworks.artworkList[0] &&
                                                                                <p className="ml-3">
                                                                                    <strong> &ensp; {album.artworks.artworkList[0].artworkName} </strong>
                                                                                    &ensp;외 {album.artworks && album.artworks.artworkList.length - 1}개
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td rowSpan={album.artworks.artworkList.length}>
                                                                        <span>{album.artworks.artworkPrice.toLocaleString()} 원</span>
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="3" className="text-center">등록된 아트워크가
                                                                        없습니다.
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            </tbody>

                                                        </table>
                                                        <div className="p-4">
                                                            {album && album.artworks && album.artworks.artworkList && (
                                                                <strong>아트워크 미리보기</strong>)}

                                                            <ul className="products list-unstyled row no-gutters row-cols-2 row-cols-lg-3 row-cols-wd-4 mb-6">
                                                            {album && album.artworks && album.artworks.artworkList && album.artworks.artworkList
                                                            .map((artwork, index) => {
                                                                return(
                                                                    <li className="col pt-4 text-center" key={index}>
                                                                        <img
                                                                            className=""
                                                                            style={{cursor: 'pointer'}}
                                                                            onClick={() => modalOpen(artwork.artworkPreviewScopeFileUrl, artwork.artworkComment)}
                                                                            src={artwork.artworkPreviewFileUrl}
                                                                            height="125"
                                                                            width="125"
                                                                            onError={imageErrorHandler}
                                                                        />
                                                                        <div className="text-gray-450 mt-2">
                                                                            <span
                                                                                className="mr-4">{artwork.artworkFileFormat}</span>
                                                                            <span
                                                                                className="">{calcSize(artwork.artworkSize)}</span>

                                                                        </div>
                                                                    </li>
                                                                )
                                                            })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && (<ImageModal
                fileUrl={selectedArtworkUrl}
                comment={selectedArtworkComment}
                open={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}/>)}
        </div>
    )
}

export default AlbumDetail;