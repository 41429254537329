import React from "react";
import { Link } from 'react-router-dom';

const SidebarMypage = () => {
    return(
        <>
            <div className="col-md-2 border-right mb-5">
                <h6 className="font-weight-medium font-size-7 pt-5 pt-lg-8  mb-5 mb-lg-7">마이페이지</h6>
                <div className="tab-wrapper">
                    <ul className="my__account-nav nav flex-column mb-0" role="tablist" id="pills-tab">
                        <li className="nav-item mx-0">
                            <Link className="nav-link d-flex align-items-center px-0 active" id="pills-one-example1-tab"
                               data-toggle="pill" href="#pills-one-example1" role="tab"
                               aria-controls="pills-one-example1" aria-selected="false"
                               to='/myPage'  >
                                <span className="font-weight-normal text-gray-600">회원정보</span>
                            </Link>
                        </li>
                        <li className="nav-item mx-0">
                            <Link className="nav-link d-flex align-items-center px-0" id="pills-two-example1-tab"
                               data-toggle="pill" href="#pills-two-example1" role="tab"
                               aria-controls="pills-two-example1" aria-selected="false"
                               to='/basket' >
                                <span className="font-weight-normal text-gray-600">장바구니</span>
                            </Link>
                        </li>
                        <li className="nav-item mx-0">
                            <Link className="nav-link d-flex align-items-center px-0" id="pills-three-example1-tab"
                               data-toggle="pill" href="#pills-three-example1" role="tab"
                               aria-controls="pills-three-example1" aria-selected="false"
                                to='/myAlbum' >
                                <span className="font-weight-normal text-gray-600">보관함/다운로드</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default SidebarMypage