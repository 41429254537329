import axios from "axios";
import * as userService from "./userService";

const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT

const apiEndpoint = process.env.REACT_APP_APIGATEWAY_ENDPOINT

// jwt 토큰 확인 필요한 모든 요청에 사용
const axiosInstanceWithToken = axios.create({
    baseURL: apiEndpoint,
    withCredentials: true,
    headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('authorization'),

        // 추가
        "Access-Control-Allow-Origin": '*',
        'Access-Control-Allow-Credentials': "true",
    }
});

// 요청 인터셉터 (요청인터셉터가 있어야 응답가능)
axiosInstanceWithToken.interceptors.request.use((config) => {
    config.headers = config.headers || {};
    config.headers.Authorization = localStorage.getItem('authorization');
    return config;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

// 응답 인터셉터
axiosInstanceWithToken.interceptors.response.use(response => {
    const newToken = response.headers['newtoken'];
    if (newToken !== undefined) {
        localStorage.setItem('authorization', newToken);
    }
    return response.data;
}, error => {
    if (axios.isCancel(error)) {
        return Promise.reject(error)
    } else {
        const newToken = error.response.headers['newtoken'];
        if (newToken) {
            localStorage.setItem('authorization', newToken);
        } else {
            const statusCode = error.response ? error.response.status : 503;
            if (statusCode === 401) { // 비정상 토큰, refresh토큰 X,  401 반환
                userService.Logout();
                sessionStorage.setItem("openSidebarAfterRedirect", true);
                window.location.href = "/"
            }
        }
    }
})
    export default axiosInstanceWithToken;