import React from "react";
import { Link, useLocation } from 'react-router-dom';

const Navgationbar = ({pageName}) => {
    const location = useLocation();
    const fromPath = location.state?.from;
    const name = location.state?.name;

    return (
        // <div class="mb-5">
            <div className="">
                <div className="page-header border-bottom">
                    <div className="container">
                        <div className="d-md-flex justify-content-between align-items-center py-4">
                            <nav className="woocommerce-breadcrumb font-size-2">
                                <Link to="/" className="h-primary">홈</Link>
                                <span className="breadcrumb-separator mx-1"><i className="fas fa-angle-right"></i></span>
                                <span>
                                    {(pageName === '/best' || fromPath === '/best') && <Link to="/best" className="h-primary">베스트</Link>}
                                    {(pageName === '/new' || fromPath === '/new') && <Link to="/new" className="h-primary">NEW</Link>}
                                    {(pageName === '/song' || fromPath === '/song') && <Link to="/song" className="h-primary">가요</Link>}
                                    {(pageName === '/popjazz' || fromPath === '/popjazz') && <Link to="/popjazz" className="h-primary">팝/재즈</Link>}
                                    {(pageName === '/classic' || fromPath === '/classic') && <Link to="/classic" className="h-primary">클래식</Link>}
                                    {(pageName === '/special' || pageName === '/specialDetail') && <Link to="/special" className="h-primary">스페셜</Link>}
                                    {pageName === '/paymentTest' && '결제테스트'}

                                    {(pageName === '/search' || fromPath === '/search') && '검색'}
                                    {/*{fromPath === '/' && '앨범 상세'}*/}

                                    {pageName === '/myPage' && '회원정보'}
                                    {pageName === '/basket' && '장바구니'}
                                    {pageName === '/myAlbum' && '보관함/다운로드'}
                                    
                                    {pageName === '/guide' && '이용안내'}
                                    {pageName === '/faq' && 'FAQ'}
                                    {pageName === '/inquiry' && '1:1문의'}
                                    {pageName === '/inquirySend' && '1:1문의'}
                                    {pageName === '/payment/success' && '결제 완료'}
                                </span>
                                {name && (
                                    <>
                                        {fromPath !== '/' && <span className="breadcrumb-separator mx-1"><i className="fas fa-angle-right"></i></span>}
                                        <span>{name}</span>
                                    </>
                                )}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        // </div>
    )
}

export default Navgationbar;