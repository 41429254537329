import React from 'react';
import { useForm } from "react-hook-form";
import axiosInstanceWithToken from "../../containers/axiosInstanceWithToken";
import * as userService from "../../containers/userService";

const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT

const PasswordAuth = ({pwdSuccess}) => {
    const { register, handleSubmit} = useForm();
    const [failed, setFailed] = React.useState(false);

    const onSubmit = async (data) =>{
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        axiosInstanceWithToken.post(apiUrl + "/user/regular/info", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then((response) =>{
                const userData = {
                    'userEmail' : response.userEmail,
                    'userPhoneNum' : response.userPhoneNum
                }
                pwdSuccess(userData);
            })
            .catch((error) => {
                console.log(error.message);

                const statusCode = error.response ? error.response.status : error.status;
                switch (statusCode) {
                    case 403 :
                        setFailed(true);
                        break
                    case 401:
                        // 토큰 X
                        break
                    default :
                        alert('서버오류')
                        break
                }
            });
        
    }

    return(
        <>
            <div className="tab-pane fade show active"
                 id="pills-one-example1" role="tabpanel"
                 aria-labelledby="pills-one-example1-tab">
                <div className=" mb-6 pb-6 mb-lg-8 pb-lg-9">
                    <div className="pt-5 pl-md-5 pt-lg-8 pl-lg-9">

                        <div className="font-weight-medium font-size-22 mb-4 pb-xl-1">회원정보 조회를 위해서 비밀번호 재인증이 필요합니다</div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div className="js-form-message">

                                        <input type="password" className="form-control rounded-0" name="name"
                                               id="exampleFormControlInput6" aria-label="Jack Wayley" required=""
                                               data-error-class="u-has-error" data-msg="Please enter your name."
                                               data-success-class="u-has-success"
                                               {...register("userPwd")}
                                        />
                                        {failed &&
                                            (<div>
                                                <font className="font-size-2" color={'red'}>비밀번호가 틀렸습니다.</font>
                                            </div>)}
                                    </div>
                                </div>


                                <div className="ml-3">
                                    <button type="submit"
                                            className="btn btn-wide btn-dark text-white rounded-0 transition-3d-hover height-60 width-390">비밀번호
                                        확인
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>
    )
}

export default PasswordAuth;