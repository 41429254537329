import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import ModalStyle from "./DownloadModal.module.css"
import {useEffect, useState} from "react";
import LoadingBar from "../common/LoadingBar";

const ModalComponent = (props) => {
    const [filename, setFilename] = useState(props.footer.content)
    const confirmHandler = () => {
        props.okay(props.footer.okayBtn.data, filename)
        // props.onHide()
    }

    const changeFilename = (e) => {
        if(e.target.value === '') {
            setFilename(props.footer.content)
        } else {
            setFilename(e.target.value)
        }
    }

    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" className={ModalStyle} centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.body.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="grid-example">
                <Container>
                    <Row>
                        <Col xs={12} md={18}>
                            {props.body.content}
                        </Col>
                        {/*<Col xs={6} md={4}>*/}
                        {/*    .col-xs-6 .col-md-4*/}
                        {/*</Col>*/}
                    </Row>
                </Container>
            </Modal.Body>
            <div style={{borderTop: "1px solid #eae8e4"}}>
                <div className="m-3">
                    파일명: <input className="mr-2" id={"fileName"} type={"text"} placeholder={filename} onChange={changeFilename}/>
                </div>
                <div className="m-3 d-flex" style={{justifyContent: "flex-start"}}>
                    <Button variant="primary" onClick={props.onHide}>{props.footer.cancelBtn.text}</Button>
                    <Button style={{marginLeft: "auto"}} variant="secondary" onClick={() => {confirmHandler(filename)}}>{props.footer.okayBtn.text}</Button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalComponent;