import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import DefaultImage from "../../assets/image/noimage.jpg";
import DOMPurify from 'dompurify'

const SpecialDetail = () => {
    const location = useLocation();
    const { data } = location.state || {};
    const [style, setStyle] = useState({});

    useEffect(() => {

    }, [data]);

    const sanitizedDescription = (description) => {
        return DOMPurify.sanitize(description);
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    const handleImageLoad = (event) => {
        const { naturalWidth, naturalHeight } = event.target;

        if (naturalWidth > naturalHeight) {
            setStyle({ maxWidth: "100%", minWidth:"80%", height: "auto" });
        } else {
            setStyle({ maxHeight: "100%", width: "auto" });
        }
    };

    return(
        <>
            <div className="container">
                <div>
                    <div className="text-center">
                        <img className="img-fluid"
                             style={style}
                             onLoad={handleImageLoad}
                             // style={{minWidth: '1000px', maxWidth: '1400px'}}
                            // width="1400px"
                             src={!data.boardFileUrl ? DefaultImage : data.boardFileUrl}
                             alt="Image-Description"/>
                    </div>


                    <div className="max-width-940 mx-auto bg-white position-relative">
                        <div className=" mt-n10 mt-md-n13 pt-5 pt-lg-7 px-3 px-md-5 pl-xl-10 pr-xl-8 pb-10">
                        <div className="">
                                <div className="mb-5 mb-lg-7">
                                    <div className="mb-2">
                                        <span className="font-size-2 text-primary">{data.type === 7? "특집" : "이벤트"}</span>
                                    </div>
                                    <h6 className="font-size-10 crop-text-2 font-weight-medium text-lh-1dot4">
                                        {data.title}
                                    </h6>
                                    <div className="font-size-2 text-secondary-gray-700">
                                        <span>{formatDate(data.createdAt)}</span>
                                    </div>
                                </div >
                                <div style={{wordBreak: "break-word"}} dangerouslySetInnerHTML={{__html: sanitizedDescription(data.content)}} className="font-size-2 text-lh-1dot72 mb-4">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default SpecialDetail;