import axios from "axios";
import { loginAction, logoutAction } from '../store/action/AuthAction';
import store from '../store/store';
import axiosInstanceWithToken from "./axiosInstanceWithToken";
// import {useDispatch}  from 'react-redux';
const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT;

export const Join = (data, setIsLoading) => {
    setIsLoading(true)
    const formData = new FormData();
    Object.keys(data).forEach(key => {
        formData.append(key, data[key]); // 각 field, value
    });

    axios({
        method: 'post',
        url: apiUrl + '/user/public/join',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).then((result) => {
        alert('회원가입 성공. 이메일에서 인증을 진행해주세요.')
        window.location.replace('/')
    }).catch((error) => {
        console.log('실패')
        console.log(error)
        const statusCode = error.response ? error.response.status : error.status;

        switch (statusCode) {
            case 409:
                alert('이미 가입된 이메일입니다. 다른 이메일을 작성해주세요.')
                break;
            case 400:
                alert('이메일 전송 오류. 다시 시도해주시거나 다른 이메일을 작성해주세요')
                break;
            case 500:
                alert("기타 오류/ 관리자에게 문의하세요")
                break;
            default:
                alert("SERVER ERROR:", statusCode)
        }
    }).finally(() => {
        setIsLoading(false)
    });
}


export const Logout = () => {
    // const dispatch = useDispatch();
    const token = localStorage.getItem('authorization')
    if(token){
        localStorage.clear();
        store.dispatch(logoutAction()); // Redux 상태 업데이트
        axios
        ({
            method: 'post',
            url: apiUrl + '/user/regular/logout',
            headers: {
                authorization : token
            },
        })
        .then(response => {
            console.log("logout success");
            window.location.replace('/')})
        .catch(error => {console.log(error)});
    }
};

export const isToken = () => {
    return !!localStorage.getItem('authorization');
};

// userInfo update
export const updateUserInfo = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
        formData.append(key, data[key]); // 각 field, value
    });

    axiosInstanceWithToken({
        method: "put",
        url: apiUrl + '/user/regular/updateUser',
        data: formData,
        headers: {
            'Content-Type' : 'multipart/form-data'
        },
    }).then(response => {
        alert('회원정보 변경이 완료되었습니다.')
    }).catch(error => {
        const statusCode = error.response ? error.response.status : 503;
        if(statusCode !== 401){ // 비정상 토큰, refresh토큰 X,  401 반환
            alert('회원정보 변경 실패. 다시 시도해주세요')
        }

    })
};

// password update
export const updatePwd = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
        formData.append(key, data[key]); // 각 field, value
    });

    axiosInstanceWithToken({
        method: "put",
        url: apiUrl + '/user/regular/updatePwd',
        data: formData,
        headers: {
            'Content-Type' : 'multipart/form-data'
        },
    }).then(response => {
        alert('비밀번호 변경이 완료되었습니다.')
    }).catch(error => {
        const statusCode = error.response ? error.response.status : 503;
        if(statusCode !== 401){ // 비정상 토큰, refresh토큰 X,  401 반환
            alert('비밀번호 변경 실패. 다시 시도해주세요')
        }
    })
};
