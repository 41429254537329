import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import axiosInstanceWithToken from "../../containers/axiosInstanceWithToken";
import ModalComponent from "./DownloadModal"
import SelectBox from "../common/SelectBox"
import LoadingBar from "../common/LoadingBar";
import DownloadProgressModalComponent from "./DownloadProgressModal";
import axios from "axios";
import './mobile.css'

const apiEndpoint = process.env.REACT_APP_APIGATEWAY_ENDPOINT

const MyAlbum = ({pageType}) => {
    const [albumList, setAlbumList] = useState(null)
    const [modalFlag, setModalFlag] = useState(false)
    const [modalBody, setModalBody] = useState({})
    const [modalFooter, setModalFooter] = useState({})
    const [sortSelected, setSortSelected] = useState('1');
    const [searchTypeSelected, setSearchTypeSelected] = useState('1');
    const [searchKeyword, setSearchKeyword] = useState('')
    const [loading, setLoading] = useState(true)
    const [downloadModalFlag, setDownloadModalFlag] = useState(false)
    const [downloadProgressModalBody, setDownloadProgressModalBody] = useState(0)
    const [downloadProgressModalFooter, setDownloadProgressModalFooter] = useState({
        cancel: {
            text: '취소'
        },
        content: ''
    })
    const [isSearch, setIsSearch] = useState(false)

    const setModalShow = (status) => {
        setModalFlag(status)
        console.log(status)
    };

    const downloadCancel = (CancelTokenSource) => {
        CancelTokenSource.cancel()
    };

    const setModalContent = (info, fileCode) => {
        setModalBody({
            title: '불법 복제 예방 안내문',
            content: '무단으로 음악저작물을 사용하면 민, 형사상 법적불이익을 받게 되며, 저작권법 제 136조(벌칙)에 의거하여 5년 이하의 징역 또는 5000만 원 이하의 벌금에 처하거나 이를 병과할 수 있습니다. 또한 동법 제 125조(손해배상의 청구)에 의거 불법행위에 대한 손해배상 책임도 있습니다.'
        })
        setModalFooter({
            title: '파일 다운로드',
            content: info.originalFilename,
            cancelBtn: {
                text: '취소'
            },
            okayBtn: {
                text: '저장',
                data: {
                    fileCode: fileCode,
                    fileExt: info.originalFilename.split('.')[info.originalFilename.split('.').length - 1],
                    fileSize: info.fileSize
                }
            }
        })
    }

    const loadMyAlbum = () => {
        // setLoading(true)
        const sortBy = sortSelected === '1' || sortSelected === '2' ? 'orderedAt' : 'albumName'
        const sortType = sortSelected === '1' || sortSelected === '3' ? 'desc' : 'asc'
        if(searchKeyword) {
            setIsSearch(true)
        }
        axiosInstanceWithToken.get("/order/regular/storage?sortBy=" + sortBy + '&sortType=' + sortType + '&searchKeyword=' + (searchKeyword ? searchKeyword : '') + '&searchType=' + (searchTypeSelected ? searchTypeSelected : ''))
            .then(res => {
                setAlbumList(res)
            })
            .catch(error => {
                setAlbumList([])
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const calcSize = (size) => {
        if (size < 1024 * 1024) {
            return Math.floor(size / 10.24) / 100 + "KB"
        } else {
            return Math.floor(size / (1024 * 10.24)) / 100 + "MB"
        }
    }

    const clickDownload = (product) => {
        let originalFilename = ''
        let fileInfo = {}
        axiosInstanceWithToken.get("/common/regular/file/info?fileCode=" + product.productFileCode)
            .then(res => {
                originalFilename = res.originalFilename
                fileInfo = res
                setModalContent(fileInfo, product.productFileCode)
                setModalShow(true)
                // if (product.productType === 2) {
                //     setModalContent(fileInfo, product.productFileCode)
                //     setModalShow(true)
                // } else if (product.productType === 3) {
                //     fileInfo.fileExt = fileInfo.originalFilename.split('.')[fileInfo.originalFilename.split('.').length - 1]
                //     downloadFile(fileInfo, originalFilename)
                // }
            })
            .catch(error => {
                alert('파일 정보를 불러오는데 실패했습니다.')
            })
    }

    const downloadFile = (info, fileName) => {
        const CancelTokenSource = axios.CancelToken.source()
        // setLoading(true)
        setModalShow(false)
        setDownloadProgressModalFooter({
            cancel: {
                text: '취소',
                cancelTokenSource: CancelTokenSource
            },
            content: fileName.includes('.' + info.fileExt) ? fileName : fileName + '.' + info.fileExt
        })
        setDownloadModalFlag(true)
        axiosInstanceWithToken.get("/common/regular/file/download?fileCode=" + info.fileCode, {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setDownloadProgressModalBody({
                    percent: percentCompleted
                })
            },
            cancelToken: CancelTokenSource.token
        })
            .then(res => {
                setDownloadProgressModalBody({
                    percent: 100
                })
                setTimeout(() => {}, 500)
                const blob = new Blob([res])
                if(blob.size === info.fileSize) {
                    const downloadUrl = window.URL.createObjectURL(blob)
                    const downloadTag = document.createElement('a')
                    downloadTag.href = downloadUrl;
                    downloadTag.setAttribute('download', fileName.includes(info.fileExt) ? fileName : fileName + '.' + info.fileExt);
                    document.body.appendChild(downloadTag)
                    downloadTag.click()
                    window.URL.revokeObjectURL(downloadUrl)
                    document.body.removeChild(downloadTag)
                    setDownloadModalFlag(false)
                    alert('다운로드가 완료되었습니다.')
                } else {
                    alert('다운로드에 실패했습니다.')
                }
            })
            .catch(error => {
                if(axios.isCancel(error)) {
                    alert('다운로드가 취소되었습니다.')
                } else if(error && error.response && error.response.status === 500) {
                    alert('내부 서버 오류로 파일 다운로드에 실패했습니다.')
                } else {
                    alert('알 수 없는 오류로 파일 다운로드에 실패했습니다.')
                }
            })
            .finally(() => {
                setModalShow(false)
                setDownloadModalFlag(false)
            })


    }

    useEffect(() => {
        loadMyAlbum()
    }, [sortSelected]);

    const sortSelectList = [
        {value: 1, label: '구매일 내림차순', role: 'Master'},
        {value: 2, label: '구매일 오름차순', role: 'Master'},
        {value: 3, label: '앨범명 내림차순', role: 'Master'},
        {value: 4, label: '앨범명 오름차순', role: 'Master'}
    ]

    const handleSortSelect = (e) => {
        setSortSelected(e.target.value)
    }

    const searchTypeSelectList = [
        {value: 1, label: '앨범명', role: 'Master'},
        {value: 2, label: '곡명', role: 'Master'},
        {value: 3, label: '가수명', role: 'Master'},
        {value: 4, label: '발매사', role: 'Master'}
    ]

    const searchBoxHandler = (e) => {
        setSearchKeyword(e.target.value)
    }

    const handleSearchTypeSelect = (e) => {
        setSearchTypeSelected(e.target.value)
    }

    if (!loading && albumList) {
        return (
            <>
                <div className={pageType === 'myAlbum' ? "tab-pane fade show active" : "tab-pane fade"}
                     id="pills-three-example1" role="tabpanel"
                     aria-labelledby="pills-three-example1-tab">
                    <div className="pt-5 pl-md-5 pt-lg-8 pl-lg-9 space-bottom-lg-2 mb-lg-4">
                        <h6 className="font-weight-medium font-size-7 ml-lg-1 mb-lg-8 pb-xl-1">보관함</h6>
                        {(albumList.length > 0 || isSearch) && <>
                            <div
                                className="d-flex justify-content-lg-between site-search ml-xl-0 ml-md-auto w-r-100 my-2 mobile-display-unset mobile-grid">
                                <SelectBox selectList={sortSelectList} selected={sortSelected}
                                           handleSelect={handleSortSelect}
                                           isSearchBox={false}></SelectBox>
                                <SelectBox selectList={searchTypeSelectList} selected={searchTypeSelected}
                                           handleSelect={handleSearchTypeSelect} isSearchBox={true}
                                           searchBoxHandler={searchBoxHandler} doSearch={loadMyAlbum}></SelectBox>
                            </div>
                            {/* 앨범 별 분류 */}
                            {albumList.length > 0 && <table id="basicsAccordion" style={{width: '100%'}}>
                                <tbody>
                                {albumList.map((album, index) => {
                                    return <tr key={album.albumCode} className="border-bottom border-top">
                                        <th className="font-weight-normal align-middle py-5">
                                            <div className="d-flex mobile-row-th mobile-row-width-th">
                                                {/* 앨범 이미지 */}
                                                <Link className="d-block" style={{width: "15%"}}>
                                                    <img className="img-fluid" style={{verticalAlign: 'top'}}
                                                         src={album.thumbnailUrl}
                                                         alt="Image-Description" height={"125px"}/>
                                                </Link>
                                                <div className="ml-xl-4" style={{width: "85%"}}>

                                                    <table className="table mb-0 card border-0">
                                                        {/* 상단 앨범 정보 */}
                                                        <thead id={`basicsHeading${index}`} className="mobile-thead">
                                                        <tr className="d-flex">
                                                            <th scope="col"
                                                                className="card-header card-collapse py-3 border-bottom-0 font-weight-medium pl-md-3 pr-md-3 bg-punch-light text-center mobile-table-th"
                                                                width="20%" >
                                                                <div
                                                                    className="pb-2 border-bottom row font-size-2 justify-content-center">
                                                                    <div>구매일</div>
                                                                </div>
                                                                <div
                                                                    className="pt-2 row font-size-2 justify-content-center">
                                                                    <div>{album.orderedAt.split('T')[0]}</div>
                                                                </div>
                                                            </th>
                                                            <th scope="col"
                                                                className="card-header card-collapse py-3 border-bottom-0 font-weight-medium pl-md-3 pr-md-3 bg-punch-light text-center mobile-table-th"
                                                                width="24%">
                                                                <div
                                                                    className="pb-2 border-bottom row font-size-2 justify-content-center">
                                                                    <div>앨범</div>
                                                                </div>
                                                                <div
                                                                    className="pt-2 row font-size-2 justify-content-center">
                                                                    <div>{album.albumName}</div>
                                                                </div>
                                                            </th>
                                                            <th scope="col"
                                                                className="card-header card-collapse py-3 border-bottom-0 font-weight-medium pl-md-3 pr-md-3 bg-punch-light text-center mobile-table-th"
                                                                width="20%">
                                                                <div
                                                                    className="pb-2 border-bottom row font-size-2 justify-content-center">
                                                                    <div>가수</div>
                                                                </div>
                                                                <div
                                                                    className="pt-2 row font-size-2 justify-content-center">
                                                                    <div>{album.albumArtist}</div>
                                                                </div>
                                                            </th>
                                                            <th scope="col"
                                                                className="card-header card-collapse py-3 border-bottom-0 font-weight-medium pl-md-3 pr-md-3 bg-punch-light text-center mobile-table-th"
                                                                width="20%">
                                                                <div
                                                                    className="pb-2 border-bottom row font-size-2 justify-content-center">
                                                                    <div>발매사</div>
                                                                </div>
                                                                <div
                                                                    className="pt-2 row font-size-2 justify-content-center">
                                                                    <div>{album.publisher}</div>
                                                                </div>
                                                            </th>
                                                            <th scope="col"
                                                                className="card-header card-collapse py-3 border-bottom-0 font-weight-medium pl-md-3 pr-md-3 bg-punch-light text-center mobile-table-th"
                                                                width="16%">
                                                                <div
                                                                    className="pb-2 border-bottom row font-size-2 justify-content-center">
                                                                    <div>펼치기 / 접기</div>
                                                                </div>
                                                                <div
                                                                    className="pt-2 row font-size-2 justify-content-center">
                                                                    <div >
                                                                        <button type="button"
                                                                                className="collapse-link btn btn-block d-flex align-items-center justify-content-center card-btn rounded-0 shadow-none p-0"
                                                                                data-toggle="collapse"
                                                                                data-target={`#basicsCollapse${index}`}
                                                                                aria-expanded="true"
                                                                                aria-controls={`basicsCollapse${index}`}>
                                                                            <svg className="minus" style={{
                                                                                marginBottom: '2px',
                                                                                marginTop: '6px'
                                                                            }}
                                                                                 xmlns="http://www.w3.org/2000/svg"
                                                                                 xlinkHref="http://www.w3.org/1999/xlink"
                                                                                 width="15px"
                                                                                 height="7px">
                                                                                <path fillRule="evenodd"
                                                                                      fill="rgb(22, 22, 25)"
                                                                                      d="M0.000,-0.000 L15.000,-0.000 L15.000,2.000 L0.000,2.000 L0.000,-0.000 Z"/>
                                                                            </svg>

                                                                            <svg className="plus"
                                                                                 xmlns="http://www.w3.org/2000/svg"
                                                                                 xlinkHref="http://www.w3.org/1999/xlink"
                                                                                 width="15px"
                                                                                 height="15px">
                                                                                <path fillRule="evenodd"
                                                                                      fill="rgb(22, 22, 25)"
                                                                                      d="M15.000,8.000 L9.000,8.000 L9.000,15.000 L7.000,15.000 L7.000,8.000 L0.000,8.000 L0.000,6.000 L7.000,6.000 L7.000,-0.000 L9.000,-0.000 L9.000,6.000 L15.000,6.000 L15.000,8.000 Z"/>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        </thead>

                                                        {/* 상세내역 */}
                                                        <tbody id={`basicsCollapse${index}`} className="collapse show"
                                                               aria-labelledby={`basicsHeading${index}`}
                                                            // data-parent="#basicsAccordion"
                                                        >
                                                        <tr className="d-flex mobile-display-none">
                                                            <td className="align-middle py-5 " width="10%">
                                                                <div
                                                                    className="d-flex align-items-center justify-content-center font-size-2"
                                                                >
                                                                    <span
                                                                        className="text text-gray-700"><b>번호</b></span>
                                                                </div>
                                                            </td>
                                                            <td className="align-middle py-5" width="30%">
                                                                <div
                                                                    className="d-flex align-items-center justify-content-center font-size-2"
                                                                >
                                                            <span
                                                                className="text-gray-700 align-items-center"><b>곡 이름</b></span>
                                                                </div>
                                                            </td>
                                                            <td className="align-middle py-5" width="15%">
                                                                <div
                                                                    className="d-flex align-items-center justify-content-center font-size-2">
                                                                <span
                                                                    className="text-gray-700 font-size-2"><b>포맷</b></span>
                                                                </div>

                                                            </td>
                                                            <td className="align-middle py-5" width="15%">
                                                                <div
                                                                    className="d-flex align-items-center justify-content-center font-size-2">
                                                                <span
                                                                    className="text-gray-700 font-size-2"><b>용량</b></span>
                                                                </div>
                                                            </td>
                                                            <td className="align-middle py-5 " width="15%">
                                                                <div
                                                                    className="d-flex align-items-center justify-content-center font-size-2"
                                                                >
                                                                    <span
                                                                        className="text text-gray-700"><b>구매일</b></span>
                                                                </div>
                                                            </td>
                                                            <td className="align-middle py-5 " width="15%">
                                                                <Link className="align-items-center font-size-2"></Link>
                                                            </td>
                                                        </tr>
                                                        {album.productList.map((product, index) => {
                                                            return <tr key={product.productId}
                                                                       className="border-top d-flex mobile-table-td ">
                                                                {/*<td className="align-middle py-5">*/}
                                                                {/*    <input type="checkbox" className=""/>*/}
                                                                {/*</td>*/}
                                                                <td className="align-middle py-5 " width="10%" >
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-center font-size-2">
                                                                        <span
                                                                            className="text-gray-700">{index + 1}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle py-5" width="30%">
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-center font-size-2"
                                                                    >
                                                                <span
                                                                    className="text-gray-700">{product.productName}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle py-5" width="15%">
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-center font-size-2"><span
                                                                        className="text-gray-700 font-size-2">{product.productFormat}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle py-5" width="15%">
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-center font-size-2">
                                                                <span
                                                                    className="text-gray-700 font-size-2">{calcSize(product.productSize)}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle py-5 " width="15%">
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-center font-size-2"
                                                                    >
                                                                <span
                                                                    className="text-gray-700">{product.orderedAt.split('T')[0]}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle py-5 " width="15%">
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-center font-size-2">
                                                                        <Link className="align-items-center font-size-2"
                                                                              onClick={e => {
                                                                                  clickDownload(product)
                                                                              }}>다운로드</Link>
                                                                    </div>

                                                                </td>
                                                            </tr>
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </th>
                                        {/*<td className="align-middle py-5">$37</td>*/}
                                        {/*<td className="align-middle py-5">*/}
                                        {/*    <span className="product__add-to-cart">ADD TO CART</span>*/}
                                        {/*</td>*/}
                                    </tr>
                                })}
                                </tbody>
                            </table>}
                            {albumList.length === 0 && <div className="space-2 space-md-3 space-lg-4 space-top-xl-2 space-bottom-xl-3 border-bottom border-top">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="font-weight-medium font-size-10 font-size-xs-25 mb-3">검색 결과가 없습니다.
                                    </div>
                                </div>
                            </div>}
                        </>}
                        {albumList.length === 0 && !isSearch &&
                            <div className="space-2 space-md-3 space-lg-4 space-top-xl-2 space-bottom-xl-3">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="font-weight-medium font-size-15 font-size-xs-25 mb-3">보관함이 비었습니다.
                                    </div>
                                    <h6 className="font-weight-medium mb-2 col-lg-7 text-center text-lh-lg mb-5">상품 구매시
                                        보관함에
                                        추가됩니다.</h6>
                                </div>
                            </div>}
                    </div>
                    {modalFlag && <ModalComponent show={modalFlag}
                                                  okay={downloadFile}
                                                  onHide={() => setModalShow(false)}
                                                  body={modalBody}
                                                  footer={modalFooter}></ModalComponent>}
                    {downloadModalFlag && <DownloadProgressModalComponent show={downloadModalFlag}
                                                     okay={() => {
                                                     }}
                                                     onHide={downloadCancel}
                                                     body={downloadProgressModalBody}
                                                     footer={downloadProgressModalFooter}
                    ></DownloadProgressModalComponent>}
                </div>
            </>
        )
    } else {
        return (
            <div style={{height: '100vh', display: 'block'}}>
                <LoadingBar/>
            </div>
        )
    }

}
export default MyAlbum