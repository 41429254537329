import React, {useEffect} from "react";
import { useForm } from "react-hook-form"
import { Link } from 'react-router-dom';
import * as userService from "../../containers/userService";
import axiosInstanceWithToken from "../../containers/axiosInstanceWithToken";

const UserInfo = ({pageType, userInfo}) => {
    const { register: registerUser, handleSubmit: handleSubmitUser, formState: { errors: errorsUser } } = useForm();
    const { register: registerPwd, handleSubmit: handleSubmitPwd, watch, formState: { errors: errorsPwd } } = useForm();
    const onSubmitPwd = data => {
        userService.updatePwd(data)
    }
    const onSubmitUser = data => {
        userService.updateUserInfo(data)
    }
    // 패스워드 확인
    const userPwd = watch('userPwd');

    useEffect(() => {
        console.log('userInfo: ' ,userInfo)
        // axios.post()
    },[]);

    //회원탈퇴
    const cancelAccount = () => {
        const result = prompt("회원탈퇴를 하시려면 '회원탈퇴' 를 작성해주세요.");
        if(result === null){
            return;
        }
        if(result === "회원탈퇴"){
            axiosInstanceWithToken.post('/user/regular/deleted-user')
                .then(res => {
                    alert("회원탈퇴가 완료되었습니다.");
                    userService.Logout();
                    window.location.href = "/";
                })
                .catch(err => {

                })
        }else {
            alert("글자를 다시 입력해주세요.");
            cancelAccount();
        }
    }

    return(
        <>

            <div className={pageType === 'myPage' ? "tab-pane fade show active" : "tab-pane fade"}
                 id="pills-one-example1" role="tabpanel"
                 aria-labelledby="pills-one-example1-tab">
                <div className="border-bottom mb-6 pb-6 mb-lg-8 pb-lg-9">
                    <div className="pt-5 pl-md-5 pt-lg-8 pl-lg-9">
                        <h6 className="font-weight-medium font-size-7 ml-lg-1 mb-lg-8 pb-xl-1">회원정보</h6>
                        <div className="font-weight-medium font-size-22 mb-4 pb-xl-1">회원정보 수정</div>
                        <form onSubmit={handleSubmitUser(onSubmitUser)}>
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div className="js-form-message">
                                        <label htmlFor="exampleFormControlInput4">이메일</label>
                                        <input type="email" className="form-control rounded-0" name="name"
                                               id="exampleFormControlInput4" aria-label="Jack Wayley" required
                                               data-error-class="u-has-error" data-msg="Please enter your name."
                                               data-success-class="u-has-success" readOnly="readOnly"
                                               value={userInfo.userEmail}
                                               {...registerUser("userEmail")}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12 mb-4">
                                    <div className="js-form-message">
                                        <label htmlFor="exampleFormControlInput5">전화번호</label>
                                        <input type="tel" className="form-control rounded-0" name="name"
                                               id="exampleFormControlInput5" aria-label="Jack Wayley" required
                                               data-error-class="u-has-error" data-msg="Please enter your name."
                                               data-success-class="u-has-success" placeholder="예시) 010-1234-1234"
                                               defaultValue={userInfo.userPhoneNum}
                                               {...registerUser("userPhoneNum",{
                                                   pattern: {
                                                       value: /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/,
                                                       message: "전화번호는 010-1234-1234 형식으로 입력해주세요",
                                                   },
                                               })}
                                        />
                                        {errorsUser.userPhoneNum &&
                                            <p className="font-size-2 text-primary">{errorsUser.userPhoneNum.message}</p>}
                                    </div>
                                </div>

                                <div className="ml-3">
                                    <button type="submit"
                                            className="btn btn-wide btn-dark text-white rounded-0 transition-3d-hover height-60 width-390">회원정보
                                        수정
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>


                </div>
                <div className="pl-md-5 pl-lg-9 space-bottom-2 space-bottom-lg-3 border-bottom">
                    <div className="font-weight-medium font-size-22 mb-4 pb-xl-1">비밀번호 변경</div>
                    <form onSubmit={handleSubmitPwd(onSubmitPwd)}>
                        <div className="row">

                            <div className="col-md-12 mb-4">
                                <div className="js-form-message">

                                    <input type="email" className="form-control rounded-0" name="name"
                                           id="exampleFormControlInput4" aria-label="Jack Wayley" required
                                           data-error-class="u-has-error" data-msg="Please enter your name."
                                           data-success-class="u-has-success" hidden="hidden"
                                           value={userInfo.userEmail}
                                           {...registerPwd("userEmail")}
                                    />
                                </div>
                            </div>


                            <div className="col-md-12 mb-4">
                                <div className="js-form-message">
                                    <label htmlFor="exampleFormControlInput6">새로운 비밀번호</label>
                                    <input type="password" className="form-control rounded-0" name="name"
                                           id="exampleFormControlInput6" aria-label="Jack Wayley" required
                                           data-error-class="u-has-error" data-msg="Please enter your name."
                                           data-success-class="u-has-success" placeholder="8~16자의 영문 대/소문자, 숫자, 특수문자"
                                           {...registerPwd("userPwd", {
                                               pattern:{
                                                   value: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,16}$/,
                                                   message: "8~16자의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요."
                                               }
                                           })}/>
                                    {errorsPwd.userPwd &&
                                        <p className="font-size-2 text-primary">{errorsPwd.userPwd.message}</p>}
                                </div>
                            </div>

                            <div className="col-md-12 mb-5">
                                <div className="js-form-message">
                                    <label htmlFor="exampleFormControlInput7">새로운 비밀번호 다시입력</label>
                                    <input type="password" className="form-control rounded-0" name="name"
                                           id="exampleFormControlInput7" aria-label="Jack Wayley" required=""
                                           data-error-class="u-has-error" data-msg="Please enter your name."
                                           data-success-class="u-has-success"
                                           {...registerPwd("confirmPwd", {
                                               validate: (value) => value === userPwd || '비밀번호가 일치하지 않습니다'
                                           })}
                                    />
                                    {errorsPwd.confirmPwd &&
                                        <p className="font-size-2 text-primary">{errorsPwd.confirmPwd.message}</p>}
                                </div>
                            </div>
                            <div className="ml-3">
                                <button type="submit"
                                        className="btn btn-wide btn-dark text-white rounded-0 transition-3d-hover height-60 width-390">비밀번호
                                    변경
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="pl-md-5 pl-lg-9 mb-5 mt-5">
                    <span>회원탈퇴를 하시려면 <Link className="border-bottom border-red-1" onClick={cancelAccount}>회원탈퇴</Link>를 클릭하세요</span>
                </div>
            </div>
        </>
    )
}

export default UserInfo;