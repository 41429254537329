import React, {useEffect, useState} from "react";
import Pagination from "../Pagination";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import DefaultImage from "../../assets/image/noimage.jpg";
import $ from "jquery"

const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT;
const searchCountPerPage = process.env.REACT_APP_PAGINATION_ALBUM_DISPLAY_COUNT;
const selectList = [
    {value: 1, label: '추천'},
    {value: 2, label: '등록순'},
    {value: 3, label: '발매순'},
    {value: 4, label: '인기순'},
]

const SearchList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const initialSelected = location.state?.selected || 0;
    const initialSearchKeyword = location.state?.searchKeyword || '';
    const [searchKeyword, setSearchKeyword] = useState(initialSearchKeyword);
    const [selected, setSelected] = useState(initialSelected); // filter
    const [sortSelected, setSortSelected] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataList, setDataList] = useState([]);
    const [category, setCategory] = useState([]);
    const [artist, setArtist] = useState([]);
    const [data, setData] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState(1);
    const [selectedArtist, setSelectedArtist] = useState(null);
    const [pageInfo, setPageInfo] = useState({
        activePage: currentPage,
        itemsCountPerPage: searchCountPerPage,
        itemCount: 0,
        pageDisplay: 5,
        onPageChange: setCurrentPage
    });

    useEffect(() => {
        $('.selectpicker').selectpicker('refresh');
        $(".dropdown-menu.inner").addClass("show");
    }, [selectList]);

    const handleSortSelectChange = (e) => {
        setSortSelected(e.target.value)
        fetchData()
    }

    const fetchData = () => {
        window.scrollTo(0, 0);
        axios.get(apiUrl + "/product/public/search",
            {params : {
                    filter : selected,
                    sortSelected : sortSelected,
                    searchData : searchKeyword,
                    currentPage: currentPage,
                    searchCountPerPage: searchCountPerPage,
                    selectedCategory: selectedCategory,
                    selectedArtist: selectedArtist,
                }})
            .then(response => {
                setDataList(response.data.albumList)
                setCategory(response.data.categoryCount)
                setArtist(response.data.artistCount)
                setData(response.data);
                const resData = response.data
                //console.log(resData);
                const pageInfo = {
                    activePage: currentPage,
                    itemsCountPerPage: searchCountPerPage,
                    itemCount: resData.totalCount,
                    pageDisplay: 5,
                    onPageChange: (page) => setCurrentPage(page)
                }
                setPageInfo(pageInfo);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setSelectedArtist(null)
        setCurrentPage(1);
        fetchData();
    };
    const handleArtistClick = (artist) => {
        setSelectedArtist(artist);
        setSelectedCategory(1)
        setCurrentPage(1);
        fetchData();
    };

    useEffect(() => {
        if(initialSelected !== 0){
            setSelected(initialSelected);
        }
        if(initialSearchKeyword !== ''){
            setSearchKeyword(initialSearchKeyword);
            setSelectedCategory(1)
            setSelectedArtist(null)
            setSortSelected(1)
        }
        fetchData();
    }, [initialSelected, initialSearchKeyword]);

    useEffect(() => {
        fetchData();
    }, [selectedCategory, selectedArtist, sortSelected, selectList, selected, searchKeyword, currentPage]);

    useEffect(() => {
        if(currentPage !== 1) {
            setCurrentPage(1)
        } else {
            fetchData();
        }
    }, [selected, searchKeyword, sortSelected, selectList]);


    const imageErrorHandler = (e) => {
        e.target.src = DefaultImage
    }

    const categoryByStr = (number) => {
        if(number === 2){
            return '가요'
        }else if(number === 3){
            return '팝/재즈'
        }else{
            return '클래식'
        }
    }

    const AlbumDetail = (albumCode, albumName) => {
        navigate('/albumDetail/' + albumCode, {state: {from : window.location.pathname, name: albumName}});
    }

    return (
        <>
            <div className="left-sidebar">
                <div className="site-content space-bottom-3 mt-8" id="content">
                    <div className="container">
                        <div className="row">
                            <div id="primary" className="content-area order-2">
                                <div
                                    className="shop-control-bar d-lg-flex justify-content-between align-items-center mb-5 text-center text-md-left">
                                    <div className="shop-control-bar__left mb-4 m-lg-0">
                                        <p className="woocommerce-result-count m-0">총 <span>{data.totalCount ? data.totalCount : 0}</span>개의
                                            앨범</p>
                                    </div>
                                    <div className="shop-control-bar__right d-md-flex align-items-center">
                                        <div className="woocommerce-ordering mb-4 m-md-0">
                                            <select
                                                className="js-select selectpicker dropdown-select orderby shadow-none outline-none py-2 max-width-130"
                                                name="orderby"
                                                data-style="border-bottom shadow-none outline-none py-2"
                                                onChange={handleSortSelectChange}
                                                >
                                                {selectList.map((e) => {
                                                    return (<option key={e.value} value={e.value}>{e.label}</option>)
                                                })}
                                            </select>
                                        </div>

                                        {/* 보기 메뉴 버튼 (이미지/리스트) */}
                                        <ul className="nav nav-tab ml-lg-4 justify-content-center justify-content-md-start ml-md-auto"
                                            id="pills-tab" role="tablist">
                                            <li className="nav-item border">
                                                <a className="nav-link p-0 height-38 width-38 justify-content-center d-flex align-items-center active"
                                                   id="pills-one-example1-tab" data-toggle="pill"
                                                   href="#pills-one-example1" role="tab"
                                                   aria-controls="pills-one-example1" aria-selected="true">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         xlinkHref="http://www.w3.org/1999/xlink" width="17px"
                                                         height="17px">
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M-0.000,0.000 L3.000,0.000 L3.000,3.000 L-0.000,3.000 L-0.000,0.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M7.000,0.000 L10.000,0.000 L10.000,3.000 L7.000,3.000 L7.000,0.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M14.000,0.000 L17.000,0.000 L17.000,3.000 L14.000,3.000 L14.000,0.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M-0.000,7.000 L3.000,7.000 L3.000,10.000 L-0.000,10.000 L-0.000,7.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M7.000,7.000 L10.000,7.000 L10.000,10.000 L7.000,10.000 L7.000,7.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M14.000,7.000 L17.000,7.000 L17.000,10.000 L14.000,10.000 L14.000,7.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M-0.000,14.000 L3.000,14.000 L3.000,17.000 L-0.000,17.000 L-0.000,14.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M7.000,14.000 L10.000,14.000 L10.000,17.000 L7.000,17.000 L7.000,14.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M14.000,14.000 L17.000,14.000 L17.000,17.000 L14.000,17.000 L14.000,14.000 Z"/>
                                                    </svg>
                                                </a>
                                            </li>
                                            <li className="nav-item border">
                                                <a className="nav-link p-0 height-38 width-38 justify-content-center d-flex align-items-center"
                                                   id="pills-two-example1-tab" data-toggle="pill"
                                                   href="#pills-two-example1" role="tab"
                                                   aria-controls="pills-two-example1" aria-selected="false">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         xlinkHref="http://www.w3.org/1999/xlink" width="23px"
                                                         height="17px">
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M-0.000,0.000 L3.000,0.000 L3.000,3.000 L-0.000,3.000 L-0.000,0.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M7.000,0.000 L23.000,0.000 L23.000,3.000 L7.000,3.000 L7.000,0.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M-0.000,7.000 L3.000,7.000 L3.000,10.000 L-0.000,10.000 L-0.000,7.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M7.000,7.000 L23.000,7.000 L23.000,10.000 L7.000,10.000 L7.000,7.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M-0.000,14.000 L3.000,14.000 L3.000,17.000 L-0.000,17.000 L-0.000,14.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M7.000,14.000 L23.000,14.000 L23.000,17.000 L7.000,17.000 L7.000,14.000 Z"/>
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                                <div className="tab-content" id="pills-tabContent">
                                    {/* 격자 보기 */}
                                    <div className="tab-pane fade show active" id="pills-one-example1"
                                         role="tabpanel"
                                         aria-labelledby="pills-one-example1-tab">
                                        {dataList.length === 0 && (
                                            <div className="d-flex flex-column align-items-center pt-8 pb-8">
                                                <div
                                                    className="font-weight-medium font-size-15 font-size-xs-25 mb-3">앨범이 존재하지 않습니다.
                                                </div>
                                                <h6 className="font-weight-medium mb-2 col-lg-7 text-center text-lh-lg mb-5">다른 앨범을 검색해주세요.</h6>
                                            </div>
                                        )}
                                        {dataList.length !== 0 && (
                                            <ul className="products list-unstyled row no-gutters row-cols-2 row-cols-lg-3 row-cols-wd-4 border-top border-left mb-6">

                                                {dataList.map((data, index) => (
                                                    <li className="product col" key={index}  style={{cursor: 'pointer'}}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            AlbumDetail(data.albumCode, data.albumName);
                                                        }} >
                                                        <div className="product__inner overflow-hidden p-3 p-md-4d875">
                                                            <div
                                                                className="woocommerce-LoopProduct-link woocommerce-loop-product__link d-block position-relative">
                                                                <div className="woocommerce-loop-product__thumbnail">
                                                                    <Link className="d-block"><img
                                                                        width="125" height="125"
                                                                        src = {data.thumbnailUrl ? data.thumbnailUrl : DefaultImage}
                                                                        onError={imageErrorHandler}
                                                                        className="d-block mx-auto attachment-shop_catalog size-shop_catalog wp-post-image"
                                                                    /></Link>
                                                                </div>
                                                                <div
                                                                    className="woocommerce-loop-product__body product__body pt-3 bg-white">
                                                                    <div
                                                                        className="text-uppercase font-size-1 mb-1 text-truncate">
                                                                        <Link>{categoryByStr(data.category)}</Link>
                                                                    </div>
                                                                    <h2 className="woocommerce-loop-product__title product__title h6 text-lh-md mb-1 text-height-2 crop-text-2 h-dark">
                                                                        <Link>{data.albumName}</Link></h2>
                                                                    <div className="font-size-2  mb-1 text-truncate">
                                                                        <Link
                                                                            className="text-gray-700">{data.albumArtist}</Link>
                                                                    </div>
                                                                    <div
                                                                        className="price d-flex align-items-center font-weight-medium font-size-3">
                                                                <span
                                                                    className="woocommerce-Price-amount amount">{data.albumPrice.toLocaleString()}
                                                                    <span
                                                                        className="woocommerce-Price-currencySymbol">원</span></span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </li>

                                                ))}
                                            </ul>
                                        )}

                                    </div>


                                    {/* 리스트보기 */}
                                    <div className="tab-pane fade" id="pills-two-example1" role="tabpanel"
                                         aria-labelledby="pills-two-example1-tab">

                                        <ul className="products list-unstyled mb-6">
                                            {dataList.length === 0 && (
                                                <div className="d-flex flex-column align-items-center pt-8 pb-3">
                                                    <div
                                                        className="font-weight-medium font-size-15 font-size-xs-25 mb-3">앨범이
                                                        존재하지 않습니다.
                                                    </div>
                                                    <h6 className="font-weight-medium mb-2 col-lg-7 text-center text-lh-lg mb-5">다른
                                                        앨범을 검색해주세요.</h6>
                                                </div>
                                            )}
                                            {dataList.map((data, index) => (
                                                <li className="product product__list" key={index}  style={{cursor: 'pointer'}}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        AlbumDetail(data.albumCode, data.albumName);
                                                    }} >
                                                    <div className="product__inner overflow-hidden p-3 p-md-4d875">
                                                        <div
                                                            className="woocommerce-LoopProduct-link woocommerce-loop-product__link row position-relative">
                                                            <div
                                                                className="col-md-auto woocommerce-loop-product__thumbnail mb-3 mb-md-0">
                                                                <Link className="d-block"><img
                                                                    width="125" height="125"
                                                                    src = {data.thumbnailUrl ? data.thumbnailUrl : DefaultImage}
                                                                    onError={imageErrorHandler}
                                                                    className="d-block mx-auto attachment-shop_catalog size-shop_catalog wp-post-image"
                                                                    alt="image-description"/></Link>
                                                            </div>
                                                            <div
                                                                className="col-md woocommerce-loop-product__body product__body pt-3 bg-white mb-3 mb-md-0">
                                                                <div
                                                                    className="text-uppercase font-size-1 mb-1 text-truncate">
                                                                    <Link>장르</Link></div>
                                                                <h2 className="woocommerce-loop-product__title product__title h6 text-lh-md mb-1 crop-text-2 h-dark">
                                                                    <Link tabIndex="0">{data.albumName}</Link></h2>
                                                                <div className="font-size-2  mb-2 text-truncate">
                                                                    <Link className="text-gray-700">{data.albumArtist}</Link>
                                                                </div>
                                                                <div
                                                                    className="price d-flex align-items-center font-weight-medium font-size-3">
                                                                <span className="woocommerce-Price-amount amount">
                                                                    <span className="woocommerce-Price-currencySymbol">{data.albumPrice.toLocaleString()}</span>원</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}

                                        </ul>

                                    </div>
                                </div>

                                <Pagination
                                    activePage={pageInfo.activePage}
                                    items={pageInfo.itemsCountPerPage} // 페이지당 항목수
                                    itemsCount={pageInfo.itemCount}
                                    pageDisplay={pageInfo.pageDisplay}
                                    onPageChange={pageInfo.onPageChange}
                                />

                            </div>

                            {/* 좌측 사이드바 */}
                            <div id="secondary" className="sidebar widget-area order-1" role="complementary">
                                <div id="widgetAccordion">
                                    <div id="woocommerce_product_categories-2"
                                         className="widget p-4d875 border woocommerce widget_product_categories">
                                        <div id="widgetHeadingOne" className="widget-head">
                                            <Link
                                                className="d-flex align-items-center justify-content-between text-dark"

                                                data-toggle="collapse"
                                                data-target="#widgetCollapseOne"
                                                aria-expanded="true"
                                                aria-controls="widgetCollapseOne">

                                                <h3 className="widget-title mb-0 font-weight-medium font-size-3">장르</h3>

                                                <svg className="mins" xmlns="http://www.w3.org/2000/svg"
                                                     xlinkHref="http://www.w3.org/1999/xlink" width="15px" height="2px">
                                                    <path fillRule="evenodd" fill="rgb(22, 22, 25)"
                                                          d="M0.000,-0.000 L15.000,-0.000 L15.000,2.000 L0.000,2.000 L0.000,-0.000 Z"/>
                                                </svg>

                                                <svg className="plus" xmlns="http://www.w3.org/2000/svg"
                                                     xlinkHref="http://www.w3.org/1999/xlink" width="15px"
                                                     height="15px">
                                                    <path fillRule="evenodd" fill="rgb(22, 22, 25)"
                                                          d="M15.000,8.000 L9.000,8.000 L9.000,15.000 L7.000,15.000 L7.000,8.000 L0.000,8.000 L0.000,6.000 L7.000,6.000 L7.000,-0.000 L9.000,-0.000 L9.000,6.000 L15.000,6.000 L15.000,8.000 Z"/>
                                                </svg>
                                            </Link>
                                        </div>

                                        <div id="widgetCollapseOne" className="mt-3 widget-content collapse show"
                                             aria-labelledby="widgetHeadingOne"
                                             data-parent="#widgetAccordion">
                                            {/* 장르 종류 */}
                                            <ul className="product-categories">
                                                {category.map((category)=> (
                                                    <li className="cat-item cat-item-9 cat-parent">
                                                        <Link onClick={() => handleCategoryClick(category.category)}>{categoryByStr(category.category)}</Link>
                                                        <span className="count">({category.categoryCount})</span>
                                                    </li>
                                                ))}

                                            </ul>
                                        </div>
                                    </div>

                                    <div id="Authors" className="widget widget_search widget_author p-4d875 border mb-5">
                                        <div id="widgetHeading21" className="widget-head">
                                            <Link className="d-flex align-items-center justify-content-between text-dark"

                                               data-toggle="collapse"
                                               data-target="#widgetCollapse21"
                                               aria-expanded="true"
                                               aria-controls="widgetCollapse21">

                                                <h3 className="widget-title mb-0 font-weight-medium font-size-3">아티스트</h3>

                                                <svg className="mins" xmlns="http://www.w3.org/2000/svg"
                                                     xlinkHref="http://www.w3.org/1999/xlink" width="15px" height="2px">
                                                    <path fillRule="evenodd" fill="rgb(22, 22, 25)"
                                                          d="M0.000,-0.000 L15.000,-0.000 L15.000,2.000 L0.000,2.000 L0.000,-0.000 Z"/>
                                                </svg>

                                                <svg className="plus" xmlns="http://www.w3.org/2000/svg"
                                                     xlinkHref="http://www.w3.org/1999/xlink" width="15px"
                                                     height="15px">
                                                    <path fillRule="evenodd" fill="rgb(22, 22, 25)"
                                                          d="M15.000,8.000 L9.000,8.000 L9.000,15.000 L7.000,15.000 L7.000,8.000 L0.000,8.000 L0.000,6.000 L7.000,6.000 L7.000,-0.000 L9.000,-0.000 L9.000,6.000 L15.000,6.000 L15.000,8.000 Z"/>
                                                </svg>
                                            </Link>
                                        </div>

                                        <div id="widgetCollapse21" className="mt-4 widget-content collapse show"
                                             aria-labelledby="widgetHeading21"
                                             data-parent="#widgetAccordion">
                                            {/* 아티스트 */}
                                            <ul className="product-categories">
                                                {artist.map((artist) => (
                                                    <li className="cat-item cat-item-9 cat-parent">
                                                        <Link onClick={() => handleArtistClick(artist.albumArtist)}>{artist.albumArtist}</Link>
                                                        <span className="count">({artist.albumCount})</span>
                                                    </li>
                                                ))}


                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SearchList;