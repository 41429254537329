import React, {useEffect, useState} from "react";
import SpecialList from "../../components/category/SpecialList";

const SpecialPage = () => {

    return(
        <>
            <SpecialList />
        </>
    )
}

export default SpecialPage;