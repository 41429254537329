import ReactPagination from "react-js-pagination";

const Pagination = ({ activePage, items, itemsCount, pageDisplay, onPageChange }) => {

    const handlePageChange = (pageNumber) => {
        onPageChange(pageNumber);
    }

    return (
        <ReactPagination
            activePage = {activePage} // 현재 페이지
            itemsCountPerPage = {items} // 한페이지 출력 아이템 수
            totalItemsCount = {itemsCount} // 총 페이지 수
            pageRangeDisplayed = {pageDisplay} // 페이지네이션 페이지 범위
            onChange = {handlePageChange} // 페이지 변경 핸들링

            // CSS
            itemClass="flex-shrink-0 flex-md-shrink-1 page-item"
            linkClass="page-link"
            activeClass="active"
            innerClass="pagination pagination__custom justify-content-md-center flex-nowrap flex-md-wrap overflow-auto overflow-md-visible mb-6"
            prevPageText="이전"
            nextPageText="다음"
        />
    );
};

export default Pagination;